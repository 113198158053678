import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { dateToLocalDateTimeString, localDateTimeStringToDate } from '../../../lib/types';
import { HeaderAccompanied, NextButton, Subheader, Tip } from '../FormPage';
import { getStateCode } from '../../../lib/address';
import { ExternalLink } from '../../../components/ExternalLink';
import { SecondaryChip } from '../../../components/SecondaryChip';
import { useSetFAQ } from '../../../useSetFAQ';

const dateFns = new DateFnsUtils();
const CLEAR_FIELD_CONSTANT = -1;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        width: '100%',
      },

      '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
        '& fieldset': {
          borderColor: '#D4DDEB',
        },
        '& input': {
          cursor: 'pointer',
        },
        '&:hover fieldset': {
          borderColor: '#005EFF',
        },
      },
    },
    chips: {
      transform: 'translate(0px, -24px)',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const ExpirationDatetimeComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      expiration_local_date_time: offer.expiration_local_datetime 
        ? localDateTimeStringToDate(offer.expiration_local_datetime) 
        : null,
    },
    onSubmit: async (values) => {
      const updatedFields = {
        expiration_local_date_time: dateToLocalDateTimeString(values.expiration_local_date_time)
      };
      update({
        offerId,
        offer: {
          ...offer,
          ...updatedFields,
        },
        updatedFields,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  const handleChipClick = (days: number) => {
    return () => {
      if (days == -1) {
        formik.setFieldValue('expiration_local_date_time', null)
        return
      }
      formik.setFieldValue('expiration_local_date_time', 
        dateFns.setMinutes(
          dateFns.setHours(
            dateFns.addDays(new Date(), days), 
            17
          ), 
          0
        ))
      }
  }

  return (
    <>
      <Header offer={offer} />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <div className={classes.chips}>
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="Tomorrow"
            onClick={handleChipClick(1)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="3 days"
            onClick={handleChipClick(3)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="1 week"
            onClick={handleChipClick(7)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="Clear"
            onClick={handleChipClick(-1)}
          />
        </div>
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              margin="normal"
              label="Expiration DateTime"
              variant="dialog"
              inputVariant="outlined"
              onChange={(date) => {
                formik.setFieldValue('expiration_local_date_time', date);
              }}
              value={formik.values.expiration_local_date_time}
            />
          </MuiPickersUtilsProvider>
        </div>
        <Tip>
          Offer expiration is a strategic lever you can use. See <ExternalLink 
          href="https://www.homelight.com/blog/buyer-how-long-is-an-offer-valid-on-a-house/">here</ExternalLink> for factors to consider.
        </Tip>
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

function getSubheader(offer?: Offer) {
  if (offer && getStateCode(String(offer.property_state)) == 'NY') {
    return <Subheader>
      Offers are not legally binding in <ExternalLink 
      href="https://blocksandlots.com/a-step-by-step-guide-from-offer-to-closing-in-nyc/">New York</ExternalLink>, 
      so you can withdraw any time before the purchase contract signing.
    </Subheader>
  }
  if (offer && getStateCode(String(offer.property_state)) == 'CA') {
    return <Subheader>
      By default in the C.A.R. Residential Purchase Agreement,
      your offer will expire at <ExternalLink
      href="https://dre.ca.gov/files/pdf/refbook/ref20.pdf">5 PM PST on the third calendar day after you sign it</ExternalLink>.
    </Subheader>
  }
  return <Subheader>
    An expiration date is optional as you can withdraw your offer as long as the seller hasn't accepted.
  </Subheader>
}

const Header = ({
  offer
} : {
  offer?: Offer
}) => (
  <>
    <HeaderAccompanied>
      When do you want your offer to expire? (optional)
    </HeaderAccompanied>
    {getSubheader(offer)}
  </>
);

export const ExpirationDatetime = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `The seller has an offer review date. How should I handle this?`,
        description: <>
          You can follow their expecations by setting an offer expiration date after the review time or not setting an expiration date at all.
          Consider game theory though for when to send the offer and how to set the expiration date. For example, having your offer in hand for a long time
          may enable the seller to shop around for competing offers. See more <ExternalLink
          href="https://www.myseattlehomesearch.com/blog/offer-review-date-what-you-need-to-know/">here</ExternalLink>.
        </>,
      },
      {
        header: `Should I send an exploding offer?`,
        description: <>
          An exploding offer is one that expires quickly. It can be used to your advantage to create a sense of urgency, 
          but it can also backfire if you do not give the seller enough time to accept your offer. See more <ExternalLink 
          href="https://medium.com/@mizzao/home-buying-strategy-in-a-sellers-market-getting-a-good-deal-8694562b8d9c">here</ExternalLink>.
        </>,
      },
    ],
    []
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={56} />
      </>
    );
  }

  return <ExpirationDatetimeComponent onContinue={onContinue} offer={offer} />;
};
