import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CurrencyTextMask } from '../../../components/CurrencyTextMask';
import { useSetFAQ } from '../../../useSetFAQ';
import { stringToNumber } from '../../../lib/types';
import { numberWithCommas } from '../../../lib/number';
import { ExternalLink } from '../../../components/ExternalLink';
import { HeaderAccompanied, Subheader, NextButton } from '../FormPage';

const PropertyPriceComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const formik = useFormik({
    initialValues: {
      offer_price: String(offer.offer_price), // input field is string for formatting
    },
    onSubmit: async (values) => {
      const updatedFields = {
        offer_price: stringToNumber(values.offer_price),
      };
      update({
        offerId,
        offer: {
          ...offer,
          ...updatedFields,
        },
        updatedFields,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  return (
    <>
      <Header offerPrice={formik.values.offer_price}/>
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <OutlineInputField
          {...formik.getFieldProps('offer_price')}
          variant="outlined"
          label="Offer Price"
          InputProps={{
            // @ts-expect-error
            inputComponent: CurrencyTextMask,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = ({ offerPrice }: { offerPrice?: string }) => {
  let valueGenerated: string | null = null;
  const numericalOfferPrice = stringToNumber(offerPrice);
  if (numericalOfferPrice) {
    const numericalValueGenerated = numericalOfferPrice * 0.03;
    valueGenerated = numberWithCommas(Math.floor(numericalValueGenerated))
  }

  return <>
    <HeaderAccompanied>
      What price do you want to offer?
    </HeaderAccompanied>
    <Subheader>
      Your offer is worth <b>${valueGenerated ? valueGenerated : 'XX,XXX'}</b> more by foregoing a buyer's agent (assuming standard 3% fee).
    </Subheader>
  </>
};

export const PropertyPrice = ({ onContinue }: { onContinue: () => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `How much should I offer?`,
        description: <>
          Real estate comps will give you the best estimate of a home's value. Sellers can include these in their disclosures, 
          and a few public tools you can use are <ExternalLink
          href="https://www.realtor.com/myhome">here</ExternalLink>, <ExternalLink
          href="https://www.redfin.com/what-is-my-home-worth">here</ExternalLink> and <ExternalLink
          href="https://www.zillow.com/how-much-is-my-home-worth/">here</ExternalLink>.
          <br/><br/>
          Ultimately, neither a realtor nor software can tell you the exact price at which a house will close. 
          Make sure to present serious offers while staying within your budget.
        </>,
      },
      {
        header: `Does this cover everything I'll spend to buy this home?`,
        description: <> 
          Not quite. Make sure to budget for <ExternalLink
          href="https://www.valuepenguin.com/mortgages/what-are-closing-costs-buying-a-home">closing costs</ExternalLink>, which are usually 2%-5% of the purchase price.
          <br/><br/>
          To understand how much house you can afford, you can use this tool <ExternalLink
          href="https://www.nerdwallet.com/mortgages/how-much-house-can-i-afford/calculate-affordability">here</ExternalLink>.
        </>,
      },
    ],
    []
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={56} />
      </>
    );
  }

  return <PropertyPriceComponent onContinue={onContinue} offer={offer} />;
};
