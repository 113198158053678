import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { dateToLocalDateString, localDateStringToDate } from '../../../lib/types';
import { HeaderAccompanied, NextButton, Subheader, Tip } from '../FormPage';
import { ExternalLink } from '../../../components/ExternalLink';
import { getStateCode } from '../../../lib/address';
import { SecondaryChip } from '../../../components/SecondaryChip';
import { useSetFAQ } from '../../../useSetFAQ';

const dateFns = new DateFnsUtils();

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        width: '100%',
      },

      '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
        '& fieldset': {
          borderColor: '#D4DDEB',
        },
        '& input': {
          cursor: 'pointer',
        },
        '&:hover fieldset': {
          borderColor: '#005EFF',
        },
      },
    },
    chips: {
      transform: 'translate(0px, -24px)',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

function generateTip(offer: Offer): JSX.Element {
  if (getStateCode(String(offer.property_state)) == 'NY') {
    return <Tip>
      It takes longer to close in New York than other states. Conventional loan buyers typically allot <ExternalLink 
      href="https://streeteasy.com/blog/how-long-does-it-take-to-close-on-a-home/">60 days</ExternalLink>.
    </Tip>
  }
  return <Tip>
    Conventional loan buyers typically allot <ExternalLink
    href="https://www.forbes.com/advisor/mortgages/how-long-does-it-take-to-close-on-a-house/">30-45 days</ExternalLink>.
  </Tip>
}

function getDefaultDays(offer: Offer): number {
  if (getStateCode(String(offer.property_state)) == 'NY') {
    return 60;
  }
  return 45;
}

const PropertyCloseDateComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      closing_local_date: offer.closing_local_date 
        ? localDateStringToDate(offer.closing_local_date) 
        : dateFns.addDays(new Date(), getDefaultDays(offer)),
    },
    onSubmit: async (values) => {
      const updatedFields = {
        closing_local_date: dateToLocalDateString(values.closing_local_date)
      };
      update({
        offerId,
        offer: {
          ...offer,
          ...updatedFields,
        },
        updatedFields,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  const handleChipClick = (days: number) => {
    return () => {
      formik.setFieldValue('closing_local_date', dateFns.addDays(new Date(), days));
    };
  };

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <div className={classes.chips}>
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="30 days"
            onClick={handleChipClick(30)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="45"
            onClick={handleChipClick(45)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="60"
            onClick={handleChipClick(60)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="90"
            onClick={handleChipClick(90)}
          />
        </div>
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              margin="normal"
              label="Close Date"
              variant="dialog"
              inputVariant="outlined"
              onChange={(date) => {
                formik.setFieldValue('closing_local_date', date);
              }}
              value={formik.values.closing_local_date}
            />
          </MuiPickersUtilsProvider>
        </div>
        {generateTip(offer)}
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderAccompanied>
      When would you like to close on the home?
    </HeaderAccompanied>
    <Subheader>
      Closing day is when you officially transfer over all the money and take ownership of the home.
    </Subheader>
  </>
);

export const PropertyCloseDate = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `What should I consider when choosing the close date?`,
        description: <>
          Sellers typically prefer an earlier close date to get paid more quickly; consult them to confirm. 
          <br/><br/>
          However, you also want to make sure <ExternalLink href="https://www.forbes.com/advisor/mortgages/how-long-does-it-take-to-close-on-a-house/">
          you have enough time</ExternalLink> to get a loan and inspect the house.
          Consult your lender on how much time they would need to prepare and finalize your loan.
        </>,
      },
    ],
    []
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={56} />
      </>
    );
  }

  return <PropertyCloseDateComponent onContinue={onContinue} offer={offer} />;
};
