import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import './formmenu.css';
import { Link as MaterialLink } from '@material-ui/core';
import { useReadOffer } from '../../hooks/useReadOffer';
import { useAppContext } from '../../AppContext';
import { isBuyerAttorneyPageEnabled, isBuyerBioPageEnabled, isBuyerFinancialsPageEnabled, isClosingCostsSectionEnabled, isDownPaymentPageEnabled, isFinancingContingencyPageEnabled, isHomeSaleContingencySectionEnabled } from './FormConfig';

function _FormMenu({ isFullWidth }: { isFullWidth?: boolean }) {
  const location = useLocation();
  const category = getCategoryFromPathname(location.pathname);

  return (
    <>
      <div>
        <Menu category={category} />
      </div>
    </>
  );
}

export const FormMenu = React.memo(_FormMenu);

function getCategoryFromPathname(pathname: string) {
  if (pathname.includes('/property/')) {
    return 'property';
  }

  if (pathname.includes('/contingency/')) {
    return 'contingency';
  }

  if (pathname.includes('/financing-contingency/')) {
    return 'financing-contingency';
  }

  if (pathname.includes('/home-sale-contingency/')) {
    return 'home-sale-contingency';
  }

  if (pathname.includes('/closing-costs/')) {
    return 'closing-costs';
  }

  if (pathname.includes('/additional-terms/')) {
    return 'additional-terms';
  }

  if (pathname.includes('/contact/')) {
    return 'contact';
  }

  if (pathname.includes('/review-offer')) {
    return 'review-offer';
  }
}

const ContentPlaceholder = ({ children }: any) => (
  <motion.div
    variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
    transition={{ duration: 0.3 }}
    className="content-placeholder"
  >
    {children}
  </motion.div>
);

const Accordion = ({
  i,
  expanded,
  setExpanded,
  children,
  header,
  isSelected,
}: any) => {
  const isOpen = expanded;
  return (
    <>
      <MaterialLink
        color="primary"
        onClick={() => setExpanded(i)}
        className="form-menu-category-header"
        style={{ color: isSelected ? '#276EF1' : '#505971' }}
      >
        {header}
      </MaterialLink>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ContentPlaceholder>{children}</ContentPlaceholder>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

const Menu = ({ category }: any) => {
  const { offerId, offlineOffer } = useAppContext();
  const location = useLocation();
  const offer = useReadOffer(offerId, offlineOffer).data;
  const [expandedCategory, setExpandedCategory] = React.useState('');

  React.useEffect(() => {
    setExpandedCategory(category);
  }, [category]);

  if (!offer) {
    return <></>
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 66 }}>
      <div style={{ marginBottom: 24 }}>
        <Accordion
          i={'property'}
          expanded={expandedCategory === 'property'}
          setExpanded={setExpandedCategory}
          isSelected={location.pathname.includes('/form/property/')}
          header={'Basic Terms'}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MenuLink to="/form/property/price">Offer Price</MenuLink>
            <MenuLink to="/form/property/payment-method">
              Payment Method
            </MenuLink>
            {isDownPaymentPageEnabled(offer) && <MenuLink to="/form/property/down-payment">Down Payment</MenuLink>}
            <MenuLink to="/form/property/earnest-money">Earnest Money</MenuLink>
            <MenuLink to="/form/property/close-date">Close Date</MenuLink>
            <MenuLink to="/form/property/expiration-datetime">Expiration DateTime</MenuLink>
          </div>
        </Accordion>
      </div>
      <div style={{ marginBottom: 24 }}>
        <Accordion
          i={'contingency'}
          expanded={expandedCategory === 'contingency'}
          setExpanded={setExpandedCategory}
          header={'Contingencies'}
          isSelected={location.pathname.includes(
            '/form/contingency/'
          )}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MenuLink to="/form/contingency/options">
              Contingency Options
            </MenuLink>
            {isFinancingContingencyPageEnabled(offer) && 
            <MenuLink to="/form/contingency/financing">
              Financing Contingency Details
            </MenuLink>
            }
          </div>
        </Accordion>
      </div>
      {isHomeSaleContingencySectionEnabled(offer) && 
      <div style={{ marginBottom: 24 }}>
        <Accordion
          i={'home-sale-contingency'}
          expanded={expandedCategory === 'home-sale-contingency'}
          setExpanded={setExpandedCategory}
          header={'Home Sale Contingency'}
          isSelected={location.pathname.includes(
            '/form/home-sale-contingency/'
          )}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MenuLink to="/form/home-sale-contingency/offer-accepted">
              Offer Accepted
            </MenuLink>
            <MenuLink to="/form/home-sale-contingency/closing-date">
              Closing Date
            </MenuLink>
            <MenuLink to="/form/home-sale-contingency/on-the-market">
              On the Market
            </MenuLink>
          </div>
        </Accordion>
      </div>
      }
      {isClosingCostsSectionEnabled(offer) &&
      <div style={{ marginBottom: 24 }}>
        <Accordion
          i={'closing-costs'}
          expanded={expandedCategory === 'closing-costs'}
          setExpanded={setExpandedCategory}
          header={'Closing Costs'}
          isSelected={location.pathname.includes('/form/closing-costs/')}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MenuLink to="/form/closing-costs/home-warranty-policy">
              Home Warranty Policy
            </MenuLink>
            <MenuLink to="/form/closing-costs/title-insurance-policy">
              Title Insurance Policy
            </MenuLink>
            <MenuLink to="/form/closing-costs/escrow-agent-fees">
              Escrow Agent Fees
            </MenuLink>
            <MenuLink to="/form/closing-costs/transfer-taxes-fees">
              Transfer Taxes and Fees
            </MenuLink>
            <MenuLink to="/form/closing-costs/rare-inspections">
              Rare Inspections
            </MenuLink>
          </div>
        </Accordion>
      </div>
      }
      <div style={{ marginBottom: 24 }}>
        <Accordion
          i={'additional-terms'}
          expanded={expandedCategory === 'additional-terms'}
          setExpanded={setExpandedCategory}
          header={'Additional Terms'}
          isSelected={location.pathname.includes('/form/additional-terms/')}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MenuLink to="/form/additional-terms/included-excluded-items">
              Included and Excluded Items
            </MenuLink>
            <MenuLink to="/form/additional-terms/other-terms">
              Other Terms
            </MenuLink>
          </div>
        </Accordion>
      </div>
      <div style={{ marginBottom: 24 }}>
        <Accordion
          i={'contact'}
          expanded={expandedCategory === 'contact'}
          setExpanded={setExpandedCategory}
          header={'Contact Info'}
          isSelected={location.pathname.includes('/form/contact/')}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MenuLink to="/form/contact/buyer-info">
              Buyer Info
            </MenuLink>
            {isBuyerFinancialsPageEnabled(offer) && 
            <MenuLink to="/form/contact/buyer-financials">Buyer Financials</MenuLink>}
            {isBuyerBioPageEnabled(offer) &&
            <MenuLink to="/form/contact/buyer-bio">
              Buyer Bio
            </MenuLink>}
            {isBuyerAttorneyPageEnabled(offer) &&
            <MenuLink to="/form/contact/buyer-attorney">
              Buyer Attorney
            </MenuLink>}
            <MenuLink to="/form/contact/seller-poc-info">
              Seller POC Info
            </MenuLink>
          </div>
        </Accordion>
      </div>
      <div style={{ marginBottom: 24 }}>
        <MenuLink to="/form/review-offer">
          <div className="form-menu-category-header">Review Offer</div>
        </MenuLink>
      </div>
    </div>
  );
};

type MenuLinkProps = {
  to: string;
  children: React.ReactNode;
};
function MenuLink({ to, children }: MenuLinkProps) {
  const location = useLocation();
  const isSelected = location.pathname.includes(to);

  return (
    <Link
      to={to}
      style={{
        color: isSelected ? '#276EF1' : '#505971',
      }}
      className="form-menu-item"
    >
      {children}
    </Link>
  );
}
