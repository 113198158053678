import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { getFullName } from '../../../lib/name';
import { Offer } from '../../../types/models/offer';

import { FormSectionOverviewItem } from '../FormSectionOverviewItem';

const useClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 12,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: 12,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 24,
      },
    },
    header: {
      marginBottom: theme.spacing(4),
      color: '#434B65',
      fontWeight: 'bold',
    },
    itemContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
      gridGap: theme.spacing(3),
    },
  })
);

type ContactOverviewProps = {
  offer: Offer;
};

export function ContactOverview({ offer }: ContactOverviewProps) {
  const classes = useClasses();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        Contact Info
      </Typography>
      <div className={classes.itemContainer}>
        <FormSectionOverviewItem
          header="Buyer Name"
          data={offer.first_name && offer.last_name ? getFullName(offer.first_name, offer.middle_name, offer.last_name) : null}
          route="contact/buyer-info"
        />
        {offer.has_cobuyer && 
        <FormSectionOverviewItem
          header="CoBuyer Name"
          data={offer.cobuyer_first_name && offer.cobuyer_last_name ? getFullName(offer.cobuyer_first_name, null, offer.cobuyer_last_name) : null}
          route="contact/buyer-info"
        />
        }
        <FormSectionOverviewItem
          header="Seller POC Name"
          data={offer.recipient_first_name && offer.recipient_last_name ? getFullName(offer.recipient_first_name, null, offer.recipient_last_name) : null}
          route="contact/seller-poc-info"
        />
      </div>
    </div>
  );
}
