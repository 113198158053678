import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Offer } from '../../../types/models/offer';
import { numberWithCommas } from '../../../lib/number';

import { isDownPaymentPageEnabled } from '../FormConfig';

import { FormSectionOverviewItem } from '../FormSectionOverviewItem';

const useClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 12,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: 12,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 24,
      },
    },
    header: {
      marginBottom: theme.spacing(4),
      color: '#434B65',
      fontWeight: 'bold',
    },
    itemContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
      gridGap: theme.spacing(3),
    },
  })
);

type PropertyOverviewProps = {
  offer: Offer;
};

export function PropertyOverview({ offer }: PropertyOverviewProps) {
  const classes = useClasses();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        Property
      </Typography>
      <div className={classes.itemContainer}>
        <FormSectionOverviewItem
          header="Price"
          data={offer.offer_price ? numberWithCommas(offer.offer_price) : offer.offer_price }
          type="currency"
          route="property/price"
        />
        <FormSectionOverviewItem
          header="Payment Method"
          data={offer.pay_method}
          route="property/payment-method"
        />
        {isDownPaymentPageEnabled(offer) &&
        <FormSectionOverviewItem
          header="Down Payment"
          data={offer.down_payment_percent}
          type="percent"
          route="property/down-payment"
        />
        }
        <FormSectionOverviewItem
          header="Earnest Money"
          data={offer.earnest_money_percent}
          type="percent"
          route="property/earnest-money"
        />
        <FormSectionOverviewItem
          header="Closing Date"
          data={offer.closing_local_date}
          route="property/close-date"
        />
      </div>
    </div>
  );
}
