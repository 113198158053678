import { TextField, withStyles } from '@material-ui/core';

export const OutlineInputField = withStyles({
  root: {
    backgroundColor: 'white',
    marginBottom: 24,
    width: '100%',
    '& label.Mui-focused': {
      color: '#005EFF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#005EFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D4DDEB',
        borderWidth: 1,
      },
      '&:hover fieldset': {
        borderColor: '#005EFF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#005EFF',
      },

      // '& input': {
      //   paddingLeft: 8
      // },
      // '& input::-webkit-input-placeholder': {
      //   color: '#666666',
      //   // paddingLeft: 8
      // }
    },
  },
})(TextField);
