import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CurrencyTextMask } from '../../../components/CurrencyTextMask';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { SecondaryChip } from '../../../components/SecondaryChip';
import { useSetFAQ } from '../../../useSetFAQ';
import { stringToNumber } from '../../../lib/types';
import { HeaderAccompanied, NextButton, Subheader, Tip } from '../FormPage';
import { getStateCode } from '../../../lib/address';
import { ExternalLink } from '../../../components/ExternalLink';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      transform: 'translate(0px, -24px)',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

function generateTip(offer: Offer): JSX.Element {
  if (getStateCode(String(offer.property_state)) == 'NY') {
    return <Tip>
      Typically <ExternalLink href="https://www.hauseit.com/earnest-money-check-nyc-home-purchase-explained/">10% of purchase price in New York</ExternalLink>.
    </Tip>
  }
  return <Tip>
    Typically <ExternalLink href="https://www.rocketmortgage.com/learn/earnest-money">1-3% of purchase price</ExternalLink>, 
    and sellers prefer higher deposits to show greater commitment.
  </Tip>
}

const PropertyEarnestMoneyComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const {
    earnest_money_percent
  } = offer;
  const classes = useStyles();

  const offerPrice = offer.offer_price;

  const formik = useFormik({
    initialValues: { earnest_money_percent },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  const handleChipClick = (percentage: number) => {
    return () => {
      if (offerPrice) {
        formik.setFieldValue('earnest_money_percent', percentage);
      }
    };
  };

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <div className={classes.root}>
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="1%"
            onClick={handleChipClick(1)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="2%"
            onClick={handleChipClick(2)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="3%"
            onClick={handleChipClick(3)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="5%"
            onClick={handleChipClick(5)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="10%"
            onClick={handleChipClick(10)}
          />
        </div>
        <OutlineInputField
          {...formik.getFieldProps('earnest_money_percent')}
          variant="outlined"
          label="Earnest Money"
          placeholder={getStateCode(String(offer.property_state)) == 'NY' ? '10' : '3'}
          InputProps={{
            startAdornment: <div />,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        {generateTip(offer)}
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderAccompanied>
      How much earnest money will you pay? 
    </HeaderAccompanied>
    <Subheader>
      Earnest money is the good faith deposit you make at purchase contract signing. 
    </Subheader>
  </>
);

export const PropertyEarnestMoney = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `What is earnest money, and why do I have to pay it?`,
        description: <>
          <ExternalLink href="https://www.propertynest.com/blog/sales/what-earnest-money/">Earnest money</ExternalLink> is 
          the good faith deposit you make when a purchase contract is signed. 
          This shows the seller you are serious about buying the home and protects the seller
          in case you break contract. If you back out for a reason not covered by contingency, 
          the seller may keep the earnest money.
          <br/><br/>
          If you close successfully, the earnest money will be applied towards your home purchase.
        </>,
      },
    ],
    []
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={56} />
      </>
    );
  }

  return (
    <PropertyEarnestMoneyComponent onContinue={onContinue} offer={offer} />
  );
};
