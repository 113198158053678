import { getStateCode } from "../../lib/address";
import { Offer, PayMethod } from "../../types/models/offer";

function isDisabledForState(offer: Offer, disabledStates: string[]): boolean {
  return disabledStates.includes(getStateCode(offer.property_state || ''))
}

function isEnabledForState(offer: Offer, enabledStates: string[]): boolean {
  return enabledStates.includes(getStateCode(offer.property_state || ''))
}

export function isDownPaymentPageEnabled(offer: Offer): boolean {
  return offer.pay_method == PayMethod.LOAN
}

export function isFinancingContingencyPageEnabled(offer: Offer): boolean {
  if (!offer.financing_contingency) {
    return false;
  }
  return isEnabledForState(offer, ['CA', 'NY'])
}

export function isExtraFinancingContingencyDetailEnabled(offer: Offer): boolean {
  return isEnabledForState(offer, ['CA'])
}

export function isHomeSaleContingencyPageEnabled(offer: Offer): boolean {
  if (!offer.home_sale_contingency) {
    return false;
  }
  return isEnabledForState(offer, ['CA'])
}

export function isHomeSaleContingencySectionEnabled(offer: Offer): boolean {
  if (!offer.home_sale_contingency) {
    return false
  }
  if (isDisabledForState(offer, ['NY', 'CA'])) {
    return false
  }
  return true
}

export function isClosingCostsSectionEnabled(offer: Offer): boolean {
  if (isDisabledForState(offer, ['NY', 'CA'])) {
    return false
  }
  return true
}

export function isBuyerFinancialsPageEnabled(offer: Offer): boolean {
  return isEnabledForState(offer, ['NY'])
}

export function isBuyerBioPageEnabled(offer: Offer): boolean {
  return isEnabledForState(offer, ['NY'])
}

export function isBuyerAttorneyPageEnabled(offer: Offer): boolean {
  return isEnabledForState(offer, ['NY'])
}
