import React from 'react';
import { useFormik } from 'formik';
import { Button } from 'antd';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import 'url-search-params-polyfill';

import { useIdentityContext } from 'react-netlify-identity';
import { useAppContext } from '../../AppContext';
import { Offer, OfferStatus } from '../../types/models/offer';
import { offerSchema, PropertyAddress } from '../../types/models/offer-validation';
import { GoogleAutocomplete } from '../Form/Address/GoogleAutocomplete';
import { OutlineInputField } from '../../components/OutlineInputField';
import { createOffer } from '../../lib/client';
import { EMPTY_UUID, SUPPORTED_STATES } from '../../lib/constants';
import './CreateOffer.css'
import { properCase } from '../../lib/case';
import { Typography } from '@material-ui/core';
import { getStateCode } from '../../lib/address';
import { ExternalLink } from '../../components/ExternalLink';

const axios = require('axios')

function initOffer(userId: string, values: PropertyAddress): Object {
  return {
    created_at_ts: Date.now(),
    user_id: userId,
    status: OfferStatus.PENDING,
    ...values,
  }
}

function generateExplainer(state?: string | null): JSX.Element | undefined {
  switch (getStateCode(String(state))) {
    case 'NY':
      return <Typography variant='body2'>
        In <ExternalLink href="https://www.nolo.com/legal-encyclopedia/new-york-home-buyers-what-does-it-mean-be-in-contract-what-happens-your-downpayment.html">
        New York</ExternalLink>, buyers submit offers, negotiate, and come to agreement on terms 
        with the seller verbally, typically via email. This agreement is not legally binding,
        but it enables you to inspect the house and review property documents while 
        your lawyers prepare the purchase contract. You are only legally bound to the purchase
        once you sign the purchase contract.
      </Typography>
    case 'CA':
      return <Typography variant='body2'>
        Our California flow is still in alpha. You'll be able to walk through our UI flow, 
        generate a verbal offer, and see what data goes into a CA purchase contract. 
        Please explore and share feedback!
      </Typography>
    default:
      return
  }
}

const PropertyAddressComponent = ({}: {}) => {
  const address = new URLSearchParams(useLocation().search).get('a');
  const streetAddress = new URLSearchParams(useLocation().search).get('addr');
  const streetAddress2 = new URLSearchParams(useLocation().search).get('addr2');
  const city = new URLSearchParams(useLocation().search).get('city');
  const county = new URLSearchParams(useLocation().search).get('county');
  const state = new URLSearchParams(useLocation().search).get('state');
  const zip = new URLSearchParams(useLocation().search).get('zip');
  const { user } = useIdentityContext();
  const { setOfflineOffer, setOfferId } = useAppContext();
  const [manualInput, setManualInput] = React.useState(false);
  let history = useHistory();
  const userId = user?.id || EMPTY_UUID;
  const formik = useFormik({
    initialValues: {
      property_street_address: streetAddress,
      property_city: city,
      property_state: state,
      property_postal_code: zip,
      property_unit_number: streetAddress2,
      property_county: county,
      property_neighborhood: undefined,
    } as PropertyAddress,
    onSubmit: async (values) => {
      if (user) {
        const response = await createOffer(user, initOffer(userId, values));
        console.log(user);
        console.log(response);
        setOfferId(response.data.ref['@ref'].id);
      } else {
        var offer = offerSchema.validateSync(initOffer(userId, values)) as Offer;
        console.log(offer)
        axios.post('/.netlify/functions/create-intent', { data: offer }, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        })
        setOfflineOffer(offer);
      }
      if (!SUPPORTED_STATES.includes(properCase(String(values.property_state)))) {
        return history.push('/outside-service-area', { state: values.property_state })
      }
      history.push('/form/property/price');
    },
  });

  const submitButtonRef = React.useRef<HTMLElement>(null);

  return (
    <>
      <div className="create-offer-wrapper">
        <Header />
        {!manualInput && !formik.values.property_street_address && (
          <>
            <GoogleAutocomplete
              defaultValue={address}
              onSelect={(_address) => {
                formik.resetForm({
                  values: {
                    ..._address,
                  },
                });
                if (submitButtonRef.current) {
                  submitButtonRef.current.focus();
                }
              }}
              onChange={() => {
                formik.resetForm({
                  values: {
                    property_street_address: null,
                    property_city: null,
                    property_state: null,
                    property_postal_code: null,
                    property_unit_number: null,
                    property_neighborhood: undefined,
                    property_county: undefined,
                  },
                });
              }}
            />

            <Button onClick={() => {
                      setManualInput(true);
                      formik.values.property_street_address = '';
                      formik.values.property_city = '';
                      formik.values.property_state = '';
                      formik.values.property_postal_code = '';
                      formik.values.property_unit_number = '';
                    }}
                    type="link"
                    style={{
                      padding: 0,
                      textAlign: 'left',
                    }}>
              Enter address manually
            </Button>
          </>
        )}
        <form
          onSubmit={formik.handleSubmit}
          className="fade-in"
          style={{ display: 'flex', flexDirection: 'column' }}
          action="#"
        >
          {(manualInput || formik.values.property_street_address) && (
            <>
              <OutlineInputField
                {...formik.getFieldProps('property_street_address')}
                label="Street Address"
                variant="outlined"
                required
              />
              <OutlineInputField
                {...formik.getFieldProps('property_city')}
                label="City"
                variant="outlined"
                required
              />
              <OutlineInputField
                {...formik.getFieldProps('property_state')}
                label="State"
                variant="outlined"
                required
              />
              <OutlineInputField
                {...formik.getFieldProps('property_postal_code')}
                label="Zip Code"
                variant="outlined"
                required
              />
              <OutlineInputField
                {...formik.getFieldProps('property_unit_number')}
                label="Apt/Unit #"
                variant="outlined"
              />
            </>
          )}
          {generateExplainer(formik.values.property_state)}
          <Button
            htmlType="submit"
            type="primary"
            style={{
              height: 60,
              width: '100%',
              maxWidth: 480,
              marginTop: 48,
              fontSize: 16,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            ref={submitButtonRef}
            disabled={!formik.values.property_street_address}
          >
            Start Offer
          </Button>
          {formik.values.property_street_address &&
          <Typography variant='caption' style={{ 
            maxWidth: 480, 
            marginTop: '0.5em', 
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
            We'll walk you through generating the offer to present to the seller! 
            By clicking the button above, you agree to our <ExternalLink href="https://ferryhome.com/terms">terms and conditions</ExternalLink>.
          </Typography>
          }
        </form>
      </div>
    </>
  );
};

const Header = () => (
  <div style={{ fontSize: 18, marginBottom: 48, fontWeight: 'bold' }}>
    Which home do you want to make an offer for?
  </div>
);

export const CreateOffer = () => {
  return <PropertyAddressComponent/>;
};
