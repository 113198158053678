import React from 'react';
import { Redirect } from 'react-router';
import { Button } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { useReadOffersByUserId } from '../../hooks/useReadOffer';
import { getStreetAddress, getPropertyFullAddress } from '../../lib/address';
import { properCase } from '../../lib/case';
import { numberWithCommas } from '../../lib/number';
import Skeleton from 'react-loading-skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
  Link,
  useHistory,
} from 'react-router-dom';
import { useAppContext } from '../../AppContext';
import { dateToLocalDateTimeString } from '../../lib/types';

const useStyles = makeStyles({
  wrapper: {
    margin: '20px 0',
    padding: '0 24px',
  },
  flex: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  table: {
    minWidth: 400,
  },
  noShadow: {
    boxShadow: 'none',
  },
  primaryButton: {
    background: '#1f6de2',
    color: '#fff',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: '#1f6de2',
      color: '#fff',
    },
  },
  createOfferButton: {
    '&::before': {
       content: '"+"',
       marginRight: 6,
    },
  },
});

export const Home = () => {
  let history = useHistory();
  const { offerId, setOfferId } = useAppContext();
  const classes = useStyles();

  const userOffers = useReadOffersByUserId();
  const hasOffers = Boolean(userOffers.data && userOffers.data.length > 0);

  if (!userOffers.isFetching && !hasOffers) {
    return <Redirect to="/create-offer" />
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.flex}>
          <h2 className={classes.grow}>My Offers</h2>
          <Button className={classes.primaryButton}
                  onClick={() => {history.push(`/create-offer`)}}>
            <span className={classes.createOfferButton}>Create Offer</span>
          </Button>
        </div>
        <TableContainer component={Paper} className={classes.noShadow}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Offer</TableCell>
                {hasOffers && (
                  <>
                    <TableCell>Status</TableCell>
                    <TableCell>Offer Price</TableCell>
                    <TableCell>Last Updated</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>

              {userOffers.isFetching && (
                <TableRow>
                  <TableCell colSpan={100}>
                    <Skeleton height={56} />
                  </TableCell>
                </TableRow>
              )}

              {hasOffers && userOffers.data!.sort((a, b) => b.ts - a.ts).map(res => (
                <TableRow key={res.offer.property_id}>
                  <TableCell>
                    <Link to="/form" onClick={() => setOfferId(res.id)}>
                      {getPropertyFullAddress(res.offer)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {properCase(res.offer.status)}
                  </TableCell>
                  <TableCell>
                  {res.offer.offer_price && (
                    <>
                      ${numberWithCommas(res.offer.offer_price)}
                    </>
                  )}
                  {!res.offer.offer_price && (
                    <>
                      &ndash;
                    </>
                  )}
                  </TableCell>
                  <TableCell>
                    {dateToLocalDateTimeString(new Date(res.ts / 1000))}
                  </TableCell>
                </TableRow>
              ))}

              {!userOffers.isFetching && !hasOffers && (
                <TableRow>
                  <TableCell>
                    No Offers Yet! Create one above.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
