import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { useAppContext } from '../../AppContext';
import { KeyboardArrowRight } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';

const useClasses = makeStyles((theme: Theme) =>
  createStyles({
    FAQ: {
      marginLeft: 100,
      height: 'fit-content',
      maxWidth: 400,
      backgroundColor: 'white',
      boxShadow: '0px 3px 5px rgb(0 0 0 / 0.04)',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
      borderRadius: 12,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        maxWidth: '100%',
        marginTop: 24,
      },
    },
  })
);

const Accordion = withStyles({
  root: {
    border: '1px solid transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: 'column',
    '& :not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
}))(MuiAccordionDetails);

export function PersistentFAQ() {
  const { FAQ } = useAppContext();
  const classes = useClasses();
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const location = useLocation();

  React.useEffect(() => {
    setExpanded(false);
  }, [location.pathname]);

  if (FAQ.length === 0) {
    return null;
  }

  const handleChange = (panel: number) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.FAQ}>
      <Typography color="primary" style={{ paddingLeft: 14, marginBottom: 12 }}>
        FAQ
      </Typography>

      {FAQ.map((faq, idx) => {
        return (
          <Accordion
            square
            expanded={expanded === idx}
            onChange={handleChange(idx)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>
                {faq.header}{' '}
                <KeyboardArrowRight
                  style={{
                    transition: '.3s ease',
                    fontSize: 20,
                    transform:
                      expanded === idx
                        ? 'rotate(90deg) translateY(4px) translateX(4px)'
                        : 'rotate(0deg) translateY(4px)',
                  }}
                />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">{faq.description}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
