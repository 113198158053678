import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Link } from 'react-router-dom';
import { Adjust, CheckCircle } from '@material-ui/icons';
const dateFns = new DateFnsUtils();

const useOverviewItemClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: 'white',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: 'fit-content',
      textTransform: 'none',
      justifyContent: 'start',
      boxShadow: '0 0 16px rgb(0 0 0 / 0.03)',
      border: '0px solid transparent',
    },
    header: {
      color: '#737985',
      fontWeight: 'bold',
    },
    data: {
      color: '#9BA0A4',
      marginTop: theme.spacing(1),
    },
  })
);
type OverviewItemProps = {
  header: React.ReactNode;
  data?: React.ReactNode;
  type?: 'currency' | 'yesNo' | 'percent' | 'years';
  route: string;
};

function convertDataForType(
  data: React.ReactNode,
  type: OverviewItemProps['type']
) {
  if (type === 'yesNo') {
    return data === true ? 'Yes' : 'No'
  }

  // below checks only convert data if data is valid
  if (!data) {
    return data
  }

  if (type === 'currency') {
    return `$${data}`;
  } 
  if (type === 'percent') {
    return `${data}%`
  }
  if (type === 'years') {
    return `${data} year(s)`

  }
  return data;
}

export function FormSectionOverviewItem({
  header,
  data,
  type,
  route,
}: OverviewItemProps) {
  const classes = useOverviewItemClasses();
  let readableData = data;
  if (type) {
    readableData = convertDataForType(data, type);
  }
  let isComplete = data !== undefined && data !== null;
  return (
    <>
      <Link to={`/form/${route}`}>
        <Button variant="outlined" className={classes.root}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle1" className={classes.header}>
                {header}
              </Typography>
              <Status isComplete={isComplete} />
            </div>
            {isComplete && (
              <Typography variant="subtitle2" className={classes.data}>
                {readableData}
              </Typography>
            )}
          </div>
        </Button>
      </Link>
    </>
  );
}

const useStatusClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 10px 4px 6px',
      borderRadius: 28,
    },
  })
);
type StatusProps = {
  isComplete: boolean;
};
const Status = ({ isComplete }: StatusProps) => {
  if (isComplete) {
    return <CompleteStatus />;
  }

  return <IncompleteStatus />;
};

const CompleteStatus = () => {
  const classes = useStatusClasses();
  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: '#E9FFF3',
      }}
    >
      <CheckCircle style={{ color: '#31B178', fontSize: 15, marginRight: 3 }} />
      <Typography
        variant="caption"
        style={{ color: '#31B178', fontSize: '.5rem', fontWeight: 'bold' }}
      >
        Complete
      </Typography>
    </div>
  );
};

const IncompleteStatus = () => {
  const classes = useStatusClasses();
  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: '#EDF3FF',
      }}
    >
      <Adjust color="primary" style={{ fontSize: 15, marginRight: 3 }} />
      <Typography
        variant="caption"
        color="primary"
        style={{ fontSize: '.5rem', fontWeight: 'bold' }}
      >
        Incomplete
      </Typography>
    </div>
  );
};
