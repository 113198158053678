/**
 * Add space between camelCase text.
 */
export function unCamelCase(str: string) {
  str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
  str = str.toLowerCase(); //add space between camelCase text
  return str;
}

function upperCase(str: string) {
  return str.toUpperCase();
}

/**
 * UPPERCASE first char of each word.
 */
export function properCase(str: string | null) {
  return str ? str.toLowerCase().replace(/^\w|\s\w/g, upperCase) : '';
}
