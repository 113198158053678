import React from 'react';
import { useFormik } from 'formik';
import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import { HeaderAccompanied, NextButton, Subheader } from '../FormPage';
import { ExternalLink } from '../../../components/ExternalLink';
import { useSetFAQ } from '../../../useSetFAQ';

const BuyerBioComponent = ({
  onContinue,
  offer,
}: {
  onContinue: (offer: Offer) => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const { buyer_bio } = offer;
  const formik = useFormik({
    initialValues: { buyer_bio },
    onSubmit: async (values) => {
      const updatedOffer = {
        ...offer,
        ...values,
      }
      update({
        offerId,
        offer: updatedOffer,
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue(updatedOffer);
    },
  });
  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <OutlineInputField
          {...formik.getFieldProps('buyer_bio')}
          variant="outlined"
          multiline
          label="Buyer Bio"
          placeholder="Jane is a teacher with TFA and focuses on providing education to underserved communities.
          Before that, she studied at Georgetown University where she graduated from the School of Foreign Services.
          She's lived in New York City for five years and is looking to settle down and purchase her first home. 
          She loves this house as it's the perfect fit for her and her multi-generational family."
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480 }}
        />
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderAccompanied>
      Provide a short professional profile of yourself.
    </HeaderAccompanied>
    <Subheader>
      It's typical in New York to provide <ExternalLink 
      href="https://montskyrealestate.com/how-buyers-agents-should-submit-an-offer-in-nyc/">a few sentences</ExternalLink> about 
      your professional background.
    </Subheader>
  </>
);

export const BuyerBio = ({ onContinue }: { onContinue: (offer: Offer) => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `What should I include in the bio, and how long should this be?`,
        description: <>
          The bio highlights your background to reaffirm that you're a serious buyer 
          and, in the case of a co-op, would be a good co-owner for the building.
          Thus, you should provide short tidbits about your career, 
          your personal interests, and your connection to the home / local area.
          <br/><br/>
          The bio should be 3-5 sentences.
        </>,
      },
    ],
    []
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header/>
        <Skeleton height={56} />
      </>
    );
  }

  return <BuyerBioComponent onContinue={onContinue} offer={offer} />;
};
