import React from 'react';
import { Form, Input, Button, Tabs, Typography, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import GoogleIcon from './google.svg';

import { useIdentityContext, User } from 'react-netlify-identity';

import './Authentication.css';
import { useAppContext } from '../../AppContext';
import { APP_NAME_TITLE_CASE } from '../../lib/constants';

const { Text } = Typography;
const { TabPane } = Tabs;

function useSignup() {
  const { signupUser } = useIdentityContext();
  const signup = React.useCallback(
    (email: string, password: string) => {
      return signupUser(email, password, {}, false);
    },
    [signupUser]
  );
  return signup;
}

function useLogin() {
  const { loginUser } = useIdentityContext();
  const login = React.useCallback(
    (email: string, password: string) => {
      return loginUser(email, password, true);
    },
    [loginUser]
  );
  return login;
}

function useRecover() {
  const { requestPasswordRecovery } = useIdentityContext();
  const recover = React.useCallback(
    (email: string) => {
      return requestPasswordRecovery(email);
    },
    [requestPasswordRecovery]
  );

  return recover;
}

function useGoogleLogin() {
  const { loginProvider } = useIdentityContext();
  const googleLogin = React.useCallback(() => {
    return loginProvider('google');
  }, [loginProvider]);
  return googleLogin;
}

export default function Authentication({
  initialEmail,
  defaultSignup
}: {
  initialEmail?: string | null,
  defaultSignup?: boolean 
}) {
  const signup = useSignup();
  const login = useLogin();
  const recover = useRecover();
  const googleLogin = useGoogleLogin();

  const { isAuthModalOpen, setIsAuthModalOpen, offlineOffer, setOfflineOffer, setOfferId, attachOfflineOfferToUser } = useAppContext();
  const [state, setState] = React.useState(defaultSignup? 'signup' : 'login');
  const [isInFlight, setIsInFlight] = React.useState(false);
  const [infoMessage, setInfoMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isGoogleInFlight, setIsGoogleInFlight] = React.useState(false);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isAuthModalOpen === false) {
      timeout = setTimeout(() => {
        setState(defaultSignup ? 'signup' : 'login');
      }, 500);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isAuthModalOpen]);

  React.useEffect(() => {
    setErrorMessage('');
    setInfoMessage('');
  }, [state]);

  const handleAuthSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setIsInFlight(true);
    try {
      switch (state) {
        case 'login': {
          const user = await login(email, password);
          await attachOfflineOfferToUser(user, offlineOffer, setOfflineOffer, setOfferId)
          setIsAuthModalOpen(false);
          break;
        }
        case 'signup': {
          await signup(email, password);
          setInfoMessage('A confirmation message was sent to your email, click the link there to continue.')
          break;
        }
        case 'recover': {
          setIsGoogleInFlight(true);
          await recover(email);
          break;
        }
      }
    } catch (e) {
      console.log(e)
      const err: string = e.json? (e.json.msg || e.json.error_description) : `Account not available. Please contact support@${APP_NAME_TITLE_CASE.toLowerCase()}.com!`;
      setErrorMessage(err);
    } finally {
      setIsInFlight(false);
      setIsGoogleInFlight(false);
    }
  };

  return (
    <>
      <div className="login-container">
        <Tabs
          style={{ display: 'flex', width: '100%' }}
          activeKey={state}
          onChange={(key) => setState(key)}
        >
          {state !== 'recover' ? (
            <>
              <TabPane
                tab="Sign Up"
                key="signup"
                style={{ flex: 1, width: '100%' }}
              >
                {/* Content of Tab Pane 1 */}
              </TabPane>
              <TabPane tab="Log In" key="login" style={{ flex: 1 }}>
                {/* Content of Tab Pane 2 */}
              </TabPane>
            </>
          ) : null}
          {state === 'recover' ? (
            <TabPane tab="Recover Password" key="recover" style={{ flex: 1 }}>
              {/* Content of Tab Pane 3 */}
            </TabPane>
          ) : null}
        </Tabs>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true, email: initialEmail }}
          onFinish={handleAuthSubmit}
        >
          <Form.Item name="email" rules={[{ required: true, message: ' ' }]}>
            <Input
              type="email"
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          {state !== 'recover' ? (
            <Form.Item
              name="password"
              rules={[{ required: true, message: ' ' }]}
            >
              <Input
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
          ) : null}

          <div style={{ width: '100%', textAlign: 'center', marginBottom: 24 }}>
            {infoMessage? <Text>{infoMessage}</Text> : null}
          </div>

          <div style={{ width: '100%', textAlign: 'center', marginBottom: 24 }}>
            {errorMessage ? <Text type="danger">{errorMessage}</Text> : null}
          </div>

          <Form.Item>
            <Button
              loading={isInFlight}
              size="large"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
            >
              {state === 'signup'
                ? 'Sign Up'
                : state === 'login'
                ? 'Log In'
                : 'Send Recovery Email'}
            </Button>
          </Form.Item>

          {state === 'login' ? (
            <Button
              onClick={() => setState('recover')}
              style={{ width: '100%' }}
              size="large"
              type="link"
            >
              Forgot Password?
            </Button>
          ) : null}

          {state !== 'recover' ? <Divider plain>OR</Divider> : null}

          {state === 'recover' ? (
            <Button
              onClick={() => setState('login')}
              style={{ width: '100%' }}
              size="large"
              type="link"
            >
              Never Mind
            </Button>
          ) : null}

          {state !== 'recover' ? (
            <Button
              loading={isGoogleInFlight}
              icon={
                <img
                  style={{ width: 16, marginRight: 12 }}
                  src={GoogleIcon}
                  alt="Google Login"
                />
              }
              size="large"
              type="default"
              style={{ width: '100%' }}
              onClick={googleLogin}
            >
              Continue with Google
            </Button>
          ) : null}
        </Form>
      </div>
    </>
  );
};
