import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  IdentityContextProvider,
  useIdentityContext,
} from 'react-netlify-identity';

import './App.css';

import { AuthenticationModal } from './components/Authentication/AuthenticationModal';
import {
  RecoveryPage,
  CatchNetlifyRecoveryNullComponent,
} from './views/Recovery';
import { Welcome } from './views/Welcome';
import { Home } from './views/Home/Home';
import { Admin } from './views/Admin/Admin';
import { AppContextProvider, useAppContext } from './AppContext';
import { FormContextProvider } from './FormContext';
import { FormRouter } from './views/Form/FormRouter';
import { ReactQueryDevtools } from 'react-query-devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './views/Form/css/Toast.css';

import MenuAppBar from './components/AppBar';
import { useViewportHeightCorrection } from './hooks/useViewportHeightCorrection';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import { RefreshProvider } from './hooks/useRefreshJWT';
import { CreateOffer } from './views/Home/CreateOffer';
import { APP_NAME_TITLE_CASE, BASE_URL } from './lib/constants';
import { OutsideServiceArea } from './views/OutsideServiceArea';
import { CreateMyUrl } from './views/Home/CreateMyUrl';
import { isAdmin } from './lib/user';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#005eff',
    },
  },
});
theme = responsiveFontSizes(theme);

interface Props {
  component: React.FunctionComponent;
  exact?: boolean;
  path: string;
}

// OptimalRedirectHome reduces number of redirects by pushing logic upfront
const OptimalRedirectHome: React.FunctionComponent = () => {
  const { isLoggedIn } = useIdentityContext();
  if (!isLoggedIn) {
    return <Redirect to="/welcome" />;
  }
  return <Redirect to="/home" />;
}

const PublicRoute: React.FunctionComponent<Props> = (props: Props) => {
  const { isLoggedIn, user } = useIdentityContext();
  const { offlineOffer, setOfflineOffer, setOfferId, attachOfflineOfferToUser } = useAppContext();
  if (!isLoggedIn) {
    return <Route {...props} />
  }
  if (offlineOffer) {
    attachOfflineOfferToUser(user, offlineOffer, setOfflineOffer, setOfferId)
    return <Redirect to="/form/review-offer" />;
  }
  return <Redirect to="/home" />;
};

const PrivateRoute: React.FunctionComponent<Props> = (props: Props) => {
  const { isLoggedIn } = useIdentityContext();
  return isLoggedIn ? <Route {...props} /> : <Redirect to="/welcome" />;
};

const AdminRoute: React.FunctionComponent<Props> = (props: Props) => {
  const { user } = useIdentityContext();
  return isAdmin(user) ? <Route {...props} /> : <OptimalRedirectHome/>;
};

const FormRoute: React.FunctionComponent<Props> = (props: Props) => {
  const { isLoggedIn } = useIdentityContext();
  const { offerId, offlineOffer } = useAppContext();
  return (isLoggedIn && offerId) || offlineOffer ? <Route {...props} /> : <OptimalRedirectHome />;
};

const _App: React.FunctionComponent = () => {
  useViewportHeightCorrection();

  return (
    <>
      <IdentityContextProvider url={BASE_URL}>
        <RefreshProvider>
          <ThemeProvider theme={theme}>
            <AppContextProvider>
              <FormContextProvider>
                <BrowserRouter>
                  <CatchNetlifyRecoveryNullComponent />
                  <div className="app-root">
                    <ToastContainer />
                    <MenuAppBar />
                    <Switch>
                      <PublicRoute exact path="/" component={Welcome} />
                      <Route exact path="/outside-service-area" component={OutsideServiceArea} />
                      <PrivateRoute path="/home" component={Home} />
                      <AdminRoute path="/admin" component={Admin} />
                      <Route path="/create-offer" component={CreateOffer} />
                      <Route path="/create-my-url" component={CreateMyUrl} />
                      <FormRoute path="/form" component={FormRouter} />
                      <Route
                        path="/recovery"
                        exact
                        component={RecoveryPage}
                      />
                      <Redirect to="/" />
                    </Switch>
                    <AuthenticationModal />
                    <ReactQueryDevtools initialIsOpen />
                  </div>
                </BrowserRouter>
              </FormContextProvider>
            </AppContextProvider>
          </ThemeProvider>
        </RefreshProvider>
      </IdentityContextProvider>
    </>
  );
};

export const App = React.memo(_App);
