import React from 'react';
import { useLocation } from 'react-router-dom';
import 'url-search-params-polyfill';
import MenuAppBar from '../components/AppBar';
import Authentication from '../components/Authentication/Authentication';

export const Welcome: React.FunctionComponent = () => {
  const email = new URLSearchParams(useLocation().search).get('email');
  return (
    <>
      <MenuAppBar hideLogin={true} />
      <Authentication initialEmail={email} defaultSignup={Boolean(email)} />
    </>
  );
};
