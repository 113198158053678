import React from 'react';
import { useFormik } from 'formik';
import { Checkbox } from 'antd';
import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import { HeaderSolo, NextButton } from '../FormPage';

const BuyerInfoComponent = ({
  onContinue,
  offer,
}: {
  onContinue: (offer: Offer) => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const {
    first_name,
    last_name,
    cobuyer_first_name,
    cobuyer_last_name,
  } = offer;
  const has_cobuyer = Boolean(offer.has_cobuyer); // checkbox only takes boolean or undefined so needs conversion
  const formik = useFormik({
    initialValues: {
      first_name,
      last_name,
      has_cobuyer,
      cobuyer_first_name,
      cobuyer_last_name,
    },
    onSubmit: async (values) => {
      const updatedOffer = {
          ...offer,
          ...values,
      };
      update({
        offerId,
        offer: updatedOffer,
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue(updatedOffer);
    },
  });
  const onCheckboxChange = (e: { target: { checked: boolean }}) => {
    formik.setFieldValue('has_cobuyer', e.target.checked)
  }
  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <OutlineInputField
          {...formik.getFieldProps('first_name')}
          variant="outlined"
          label="First Name"
          placeholder="Jane"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('last_name')}
          variant="outlined"
          label="Last Name"
          placeholder="Lopez"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480 }}
        />
        <Checkbox
          checked={formik.values.has_cobuyer}
          onChange={onCheckboxChange}
        >
          Do you have a co-buyer?
        </Checkbox>
        {formik.values.has_cobuyer && <>
        <OutlineInputField
          {...formik.getFieldProps('cobuyer_first_name')}
          variant="outlined"
          label="Cobuyer First Name"
          placeholder="John"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480, marginTop: 42 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('cobuyer_last_name')}
          variant="outlined"
          label="Cobuyer Last Name"
          placeholder="Lee"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480 }}
        />
        </>}
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderSolo>
      What's your (and your co-buyer's) basic info?
    </HeaderSolo>
  </>
);

export const BuyerInfo = ({ onContinue }: { onContinue: (offer: Offer) => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  if (response.isFetching || !offer) {
    return (
      <>
        <Header/>
        <Skeleton height={56} />
      </>
    );
  }

  return <BuyerInfoComponent onContinue={onContinue} offer={offer} />;
};
