import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { MobileFormMenu } from './MobileFormMenu';
import { useWindowSize } from '../hooks/useWindowSize';
import { useIdentityContext } from 'react-netlify-identity';
import { useAppContext } from '../AppContext';
import { Button } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { APP_NAME_TITLE_CASE } from '../lib/constants';
import { LOGO_STYLE } from '../lib/style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    offset: theme.mixins.toolbar,
  })
);

export interface MenuAppBarProps {
  hideLogin?: boolean;
}

export default function MenuAppBar(props: MenuAppBarProps) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { isLoggedIn, user, logoutUser } = useIdentityContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { setIsAuthModalOpen, setOfferId } = useAppContext();
  const { width } = useWindowSize();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogin = () => {
    setIsAuthModalOpen((x) => !x);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isForm = location.pathname.includes('/form/')

  return (
    <>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: 'white',
          boxShadow: '0px 20px 20px rgb(0 0 0 / 3%)',
        }}
        elevation={3}
      >
        <Toolbar>
          {width < 575 && isForm && (
            <MobileFormMenu>
              {({ toggleDrawer }: { toggleDrawer: any }) => {
                return (
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="primary"
                    aria-label="menu"
                    onClick={toggleDrawer('left', true)}
                  >
                    <MenuIcon />
                  </IconButton>
                );
              }}
            </MobileFormMenu>
          )}
          <Typography variant="h6" className={classes.title}>
            <Link to="/">
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                }}
              >
                <span
                  style={{
                    fontSize: 20,
                    ...LOGO_STYLE,
                  }}
                >
                  {APP_NAME_TITLE_CASE.toLowerCase()}
                </span>
              </div>
            </Link>
          </Typography>
          {!props.hideLogin && <>
          {isLoggedIn ? (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="primary"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disabled={true}>
                  {user?.email}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    logoutUser();
                    setOfferId(''); // clear user related state
                    history.push('/')
                  }}
                >
                  Log Out
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <Button onClick={handleLogin} type="link">
              Log In
            </Button>
          )}
          </>}
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
}
