import React from 'react';
import { useFormik } from 'formik';
import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import { HeaderAccompanied, NextButton, Subheader } from '../FormPage';

const SellerPocInfoComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const {
    recipient_first_name,
    recipient_last_name,
  } = offer;
  const formik = useFormik({
    initialValues: {
      recipient_first_name,
      recipient_last_name,
    },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });
  
  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <OutlineInputField
          {...formik.getFieldProps('recipient_first_name')}
          variant="outlined"
          label="First Name"
          placeholder="Emily"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('recipient_last_name')}
          variant="outlined"
          label="Last Name"
          placeholder="Miller"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480 }}
        />
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderAccompanied>
      What's seller's POC info?
    </HeaderAccompanied>
    <Subheader>
      If the seller has representation (e.g. agent), that's the POC. Otherwise, include the seller's info here. 
    </Subheader>
  </>
);

export const SellerPocInfo = ({ onContinue }: { onContinue: () => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  if (response.isFetching || !offer) {
    return (
      <>
        <Header/>
        <Skeleton height={56} />
      </>
    );
  }

  return <SellerPocInfoComponent onContinue={onContinue} offer={offer} />;
};
