import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useIdentityContext } from 'react-netlify-identity';
import { useAppContext } from '../../AppContext';
import { Offer, OfferStatus } from '../../types/models/offer';
import { toast, Slide } from 'react-toastify';
import { submitOffer } from '../../lib/client';

const useClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 12,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: 12,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 24,
      },
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '80%',
    },
    header: {
      marginBottom: theme.spacing(4),
      color: '#434B65',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    itemContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
      gridGap: theme.spacing(3),
    },

    wrapper: {
      margin: theme.spacing(2),
      position: 'relative',
      width: 'fit-content',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    caption: {
      textAlign: 'center',
      display: 'block',
    }
  })
);

export function FormHolisticActions({ offer }: { offer: Offer }) {
  const classes = useClasses();
  const { user } = useIdentityContext();

  const { offerId, setIsAuthModalOpen } = useAppContext();

  const [state, setState] = React.useState({ isSubmittingOffer: false });

  const handleSubmitOffer = async () => {
    if (!user) {
      setIsAuthModalOpen(true)
      return
    }

    setState((x) => ({ ...x, isSubmittingOffer: true }));

    try {
      await submitOffer(user, offerId, offer);
      setState((x) => ({ ...x, isSubmittingOffer: false }));
      toast.success('The generated offer will be sent to your email shortly!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide,
      });
    } catch (e: any) {
      setState((x) => ({ ...x, isSubmittingOffer: false }));
      toast.error(`Sorry, we were unable to submit your offer. We've flagged our team for help!`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide,
      });
      throw e;
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        {offer.status != OfferStatus.PENDING 
          ? <>Ready to re-generate offer?</>
          : <>Ready to generate offer?</>
        }
      </Typography>
      <div className={classes.wrapper}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={state.isSubmittingOffer}
          onClick={handleSubmitOffer}
        >
          <Typography>
            {user ? 'Generate' : 'Sign up'}
          </Typography>
        </Button>
        {state.isSubmittingOffer && (
          <CircularProgress
            size={24}
            color="primary"
            className={classes.buttonProgress}
          />
        )}
      </div>
      {user && <Typography variant="caption" className={classes.caption}>
        By clicking this button, we'll send the offer to your inbox where you can review and then forward to the seller.
      </Typography>}
    </div>
  );
}
