import { useIdentityContext } from 'react-netlify-identity';
import { useMutation, useQueryCache } from 'react-query';
import { Offer } from '../types/models/offer';

const axios = require('axios');

export function useUpdateOffer() {
  const { user } = useIdentityContext();
  const accessToken = user?.token?.access_token;
  const cache = useQueryCache();

  const updateOffer = async ({
    offerId,
    offer,
    updatedFields,
    setOfflineOffer,
  }: {
    offerId: string;
    offer: Offer;
    updatedFields: object;
    setOfflineOffer: (offer: Offer | null) => void
  }) => {
    if (!accessToken) {
      setOfflineOffer(offer)
      return offer
    }
    // user_id used for data and auth validation
    const { user_id } = offer;
    const res = await axios.post(`/.netlify/functions/update-offer/${offerId}`, { data: { user_id, ...updatedFields }}, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    })
    return res.data.data as Offer;
  };

  const [update, updateStatus] = useMutation(updateOffer, {
    onSuccess: (data: Offer, variables) => {
      // https://react-query.tanstack.com/guides/updates-from-mutation-responses
      // console.log('update')
      // console.log(variables.offerId)
      // console.log(accessToken)
      // console.log(data)
      cache.setQueryData(['offer', variables.offerId, accessToken], data)
    },
  });

  return { update, updateStatus };
}
