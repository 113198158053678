import DateFnsUtils from '@date-io/date-fns';

const dateFns = new DateFnsUtils();

export function stringToNumber(s: string | null | undefined): number {
  return Number(s?.replace(/\D/g, ''));
}

export function booleanToYesNoString(b: boolean | null ): string | null {
  if (b) {
    return 'yes';
  }
  if (b === false) {
    return 'no';
  }
  return null;
}

export function yesNoStringToBoolean(s: string | null ): boolean | null {
  if (s === 'yes') {
    return true;
  }
  if (s === 'no') {
    return false;
  }
  return null;
}

export function localDateStringToDate(s: string): Date {
  return dateFns.parse(s, 'yyyy-MM-dd');
}

export function dateToLocalDateString(d: Date | null): string | null {
  if (d == null) {
    return null;
  }
  return dateFns.format(d, 'yyyy-MM-dd');
}

export function localDateTimeStringToDate(s: string): Date {
  return dateFns.parse(s, 'yyyy-MM-dd h:mma');
}

export function dateToLocalDateTimeString(d: Date | null): string | null {
  if (d == null) {
    return null;
  }
  return dateFns.format(d, 'yyyy-MM-dd h:mma');
}