import { Offer } from "../types/models/offer";

export function getFullName(firstName?: string | null, middleName?: string | null, lastName?: string | null): string{
  return [
    firstName,
    middleName,
    lastName
  ]
    .filter((x) => Boolean(x))
    .join(' ')
}

export function getFullBuyerName(offer: Offer): string {
  const buyerName = getFullName(offer.first_name, null, offer.last_name);
  if (!offer.has_cobuyer) {
    return buyerName;
  }
  const cobuyerName = getFullName(offer.cobuyer_first_name, null, offer.cobuyer_last_name);
  return `${buyerName} and ${cobuyerName}`;
}