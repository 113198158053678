import { Offer } from "../types/models/offer";
import { properCase } from './case';

const stateCodeMap: Record<string, string> = {
  'Alabama': 'AL',
  'Alaska': 'AK',
  'Arizona': 'AZ',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY',
};

export function getStreetAddress(offer: Offer): string|null {
  return offer.property_street_address ?
    properCase(offer.property_street_address.trim()) : null;
}

export function getCity(offer: Offer): string|null {
  return offer.property_city ?
    properCase(offer.property_city.trim()) : null;
}

export function getState(offer: Offer): string|null {
  return offer.property_state ?
    getStateCode(offer.property_state!.trim()) : null;
}

export function getFullAddressNoZip(street_address: string | null,
  city: string | null, state: string | null): string {
  return `${street_address}, ${city}, ${state}`
}

export function getCityStateZip(city: string | null, state: string | null,
  zip: string | null) {
  return `${city}, ${state} ${zip}`
}

export function getPropertyFullAddress(offer: Offer): string {
  return [
    getStreetAddress(offer),
    offer.property_unit_number,
    getCity(offer),
    getState(offer),
  ]
    .filter((x) => Boolean(x))
    .join(', ') + ' ' + offer.property_postal_code;
}

export function getPropertyAddressWithUnit(offer: Offer): string {
  return getCombinedStreetAddress(getStreetAddress(offer),
    offer.property_unit_number);
}

export function getCombinedStreetAddress(street_address: string | null,
  street_address_2: string | null): string {
  return [street_address, street_address_2].filter((x) => Boolean(x)).join(', ');
}

export function getStateCode(state: string): string {
  if (state.length === 2) {
    return state.toUpperCase();
  }
  const stateCode = stateCodeMap[properCase(state)];
  return stateCode ? stateCode : state;
}
