import * as yup from 'yup';

const nullableBoolSchema = yup.boolean().defined().nullable().default(null);

const nullableStringSchema = yup.string().defined().nullable().default(null);

const requiredStringSchema = yup.string().required();

const nullableLocalDateSchema = yup.string().defined().nullable().default(null);

const nullableLocalDateTimeSchema = yup.string().defined().nullable().default(null);

const positiveNumberSchema = yup.number().positive().defined().nullable().default(null);

const moneyValueSchema = positiveNumberSchema.round('floor');

const percentSchema = positiveNumberSchema.min(0).max(100);

const notRequiredUuidSchema = yup.string().uuid().notRequired();

const optionalFileSchema = yup.object({
  last_modified: yup.number(),
  size: positiveNumberSchema,
  type: nullableStringSchema,
  file_name: nullableStringSchema,
  encoded_file: nullableStringSchema,
}).default(undefined);

export const propertyTermsSchema = yup
  .object({
    property_street_address: nullableStringSchema,
    property_city: nullableStringSchema,
    property_state: nullableStringSchema,
    property_postal_code: nullableStringSchema,
    property_unit_number: nullableStringSchema,
    property_id: notRequiredUuidSchema,
    property_county: yup.string().nullable(),
    property_neighborhood: yup.string().nullable(),
  })
  .defined();

export const basicTermsSchema = yup
  .object({
    offer_price: moneyValueSchema,
    pay_method: nullableStringSchema.oneOf(['LOAN', 'CASH', null] as const),
    down_payment_percent: percentSchema,
    earnest_money_percent: percentSchema,
    closing_local_date: nullableLocalDateSchema,
    expiration_local_datetime: nullableLocalDateTimeSchema,
  })
  .defined();

export const contingenciesSelecionSchema = yup
  .object({
    financing_contingency: nullableBoolSchema,
    appraisal_contingency: nullableBoolSchema,
    home_sale_contingency: nullableBoolSchema,
    inspection_contingency: nullableBoolSchema,
  })
  .defined();

export const financingContingenciesSchema = yup
  .object({
    preapproval_type: nullableStringSchema.oneOf([
      'PREQUALIFIED',
      'PREAPPROVAL',
      'UNDERWRITTEN',
      null
    ]),
    loan_type: nullableStringSchema.oneOf([
      'CONVENTIONAL',
      'FHA',
      'VA',
      'OTHER',
      null,
    ]),
    loan_rate_type: nullableStringSchema.oneOf([
      'FIXED',
      'ARM',
      'UNKNOWN',
      null,
    ]),

    max_acceptable_interest_rate: positiveNumberSchema,
    max_acceptable_mortgage_points: positiveNumberSchema,
  })
  .defined();

export const homeSalesContingenciesSchema = yup
  .object({
    current_home_offer_accepted: nullableBoolSchema,
    current_home_closing_local_date: nullableLocalDateSchema,
    current_home_listed: nullableBoolSchema,
    remove_financing_and_home_sale_contingencies_on_backup_offer_allowed: nullableBoolSchema,
  })
  .defined();

export const closingCostsSchema = yup
  .object({
    home_warranty_seller_paid: nullableBoolSchema,
    title_insurance_seller_paid: nullableBoolSchema,
    escrow_agent_fees_seller_paid: nullableBoolSchema,
    transfer_taxes_and_fees_seller_paid: nullableBoolSchema,
    max_home_warranty_policy_price: moneyValueSchema,
    title_insurance_policy_breakdown: nullableStringSchema.oneOf([
      'HALF_HALF',
      'SELLER_PAYS_FULL',
      null,
    ]),
    escrow_agent_fee_breakdown: nullableStringSchema.oneOf([
      'EACH_PAY_OWN',
      'SELLER_PAYS_FULL',
      null,
    ]),
    county_transfer_tax_or_fee_seller_paid: nullableBoolSchema,
    city_transfer_tax_or_fee_seller_paid: nullableBoolSchema,
    any_private_transfer_fee_seller_paid: nullableBoolSchema,
    hoa_transfer_fee_seller_paid: nullableBoolSchema,
    hoa_fees_for_doc_prep_seller_paid: nullableBoolSchema,
    septic_inspection_seller_paid: nullableBoolSchema,
    well_inspection_seller_paid: nullableBoolSchema,
    property_boundary_survey_seller_paid: nullableBoolSchema,
  })
  .defined();

export const additionalTermsSchema = yup
  .object({
    new_home_primary_residence: nullableBoolSchema,
    preferred_title_company_name: nullableStringSchema,
    items_included: nullableStringSchema,
    items_excluded: nullableStringSchema,
    rent_back_allowed: nullableBoolSchema,
    more_than_thirty_days_rent_back_allowed: nullableBoolSchema,
    loss_limited_to_earnest_money: nullableBoolSchema,
    dispute_limited_to_arbitration: nullableBoolSchema,
    other_terms: nullableStringSchema,
    buyer_bio: nullableStringSchema,
  })
  .defined();

export const contactInfoSchema = yup
  .object({
    first_name: nullableStringSchema,
    middle_name: nullableStringSchema,
    last_name: nullableStringSchema,
    email: nullableStringSchema.email(),
    phone: nullableStringSchema,
    street_address: nullableStringSchema,
    unit_number: nullableStringSchema,
    city: nullableStringSchema,
    state: nullableStringSchema,
    postal_code: nullableStringSchema,
    country: nullableStringSchema,
    has_cobuyer: nullableBoolSchema,
    cobuyer_first_name: nullableStringSchema,
    cobuyer_last_name: nullableStringSchema,
    debt_to_income_ratio: positiveNumberSchema,
    post_closing_liquidity_years: positiveNumberSchema,
    buyer_attorney_name_and_cert: nullableStringSchema,
    buyer_attorney_title_and_office: nullableStringSchema,
    buyer_attorney_street_address: nullableStringSchema,
    buyer_attorney_street_address_2: nullableStringSchema,
    buyer_attorney_city: nullableStringSchema,
    buyer_attorney_state: nullableStringSchema,
    buyer_attorney_postal_code: nullableStringSchema,
    buyer_attorney_tel: nullableStringSchema,
    buyer_attorney_fax: nullableStringSchema,
    buyer_attorney_email: nullableStringSchema.email(),
  })
  .defined();

export const documentsSchema = yup
  .object({
    preapproval_letter: optionalFileSchema,
    personal_letter: optionalFileSchema,
    proof_of_funds: optionalFileSchema,
  })
  .defined();

export const recipientSchema = yup
  .object({
    recipient_first_name: nullableStringSchema,
    recipient_last_name: nullableStringSchema,
    recipient_email: nullableStringSchema,
    recipient_id: notRequiredUuidSchema,
  })
  .defined();

export const metadataSchema = yup
  .object({
    user_id: requiredStringSchema.uuid(),
    status: requiredStringSchema.oneOf([
      'PENDING',
      'SUBMITTED',
      'ACCEPTED',
      'REJECTED',
      'EXPIRED',
    ]).default('PENDING'),
    created_at_ts: yup.number().required().default(function () {
      return Date.now();
    }),
    submitted_at_ts: yup.number().notRequired(),
  })
  .defined();

export const offerSchema = propertyTermsSchema
  .concat(basicTermsSchema)
  .concat(contingenciesSelecionSchema)
  .concat(financingContingenciesSchema)
  .concat(homeSalesContingenciesSchema)
  .concat(closingCostsSchema)
  .concat(additionalTermsSchema)
  .concat(contactInfoSchema)
  .concat(documentsSchema)
  .concat(recipientSchema)
  .concat(metadataSchema);

export type PropertyAddress = yup.InferType<typeof propertyTermsSchema>;