import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { Offer } from '../../../types/models/offer';
import { IncludedExcludedItems } from './IncludedExcludedItems';
import { OtherTerms } from './OtherTerms';

export function AdditionalTermsRouter() {
  let match = useRouteMatch();
  let history = useHistory();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/included-excluded-items`}>
          <IncludedExcludedItems
            onContinue={() => {
              history.push(`${match.path}/other-terms`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/other-terms`}>
          <OtherTerms
            onContinue={() => {
              return history.push('/form/contact')
            }}
          />
        </Route>
        <Route>
          <Redirect to={`${match.path}/included-excluded-items`} />
        </Route>
      </Switch>
    </>
  );
}
