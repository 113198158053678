import React from 'react';
import { useFormik } from 'formik';
import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import { Typography } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import { HeaderAccompanied, NextButton, Subheader } from '../FormPage';
import { useSetFAQ } from '../../../useSetFAQ';

const BuyerAttorneyComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const {
      buyer_attorney_name_and_cert,
      buyer_attorney_title_and_office,
      buyer_attorney_street_address,
      buyer_attorney_street_address_2,
      buyer_attorney_city,
      buyer_attorney_state,
      buyer_attorney_postal_code,
      buyer_attorney_tel,
      buyer_attorney_fax,
      buyer_attorney_email,
    } = offer;
  const formik = useFormik({
    initialValues: {
      buyer_attorney_name_and_cert,
      buyer_attorney_title_and_office,
      buyer_attorney_street_address,
      buyer_attorney_street_address_2,
      buyer_attorney_city,
      buyer_attorney_state,
      buyer_attorney_postal_code,
      buyer_attorney_tel,
      buyer_attorney_fax,
      buyer_attorney_email,
    },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });
  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_name_and_cert')}
          variant="outlined"
          label="Name and Certification"
          placeholder="Adam Brown, Esq."
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_title_and_office')}
          variant="outlined"
          label="Title and Office"
          placeholder="Partner, Brown Associates PLLC"
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_street_address')}
          variant="outlined"
          label="Street Address"
          placeholder="123 Market St"
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_street_address_2')}
          variant="outlined"
          label="Street Address Pt. 2 (optional)"
          placeholder="Fifth Floor"
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_city')}
          variant="outlined"
          label="City"
          placeholder="New York City"
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_state')}
          variant="outlined"
          label="State"
          placeholder="New York"
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_postal_code')}
          variant="outlined"
          label="Zip Code"
          placeholder="10045"
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_tel')}
          variant="outlined"
          label="Tel"
          placeholder="(888) 123-4567"
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_fax')}
          variant="outlined"
          label="Fax"
          placeholder="(212) 123-4567"
          style={{ maxWidth: 480 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('buyer_attorney_email')}
          variant="outlined"
          label="Email"
          placeholder="adam@brownassociates.com"
          style={{ maxWidth: 480 }}
        />
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderAccompanied>
      What's your attorney's contact info?
    </HeaderAccompanied>
    <Subheader>
      If you don't have one yet, leave this blank. In New York, it is recommended to get an attorney early.
    </Subheader>
  </>
);

export const BuyerAttorney = ({ onContinue }: { onContinue: () => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `Why is an attorney recommended?`,
        description: <>
         In New York, attorneys are responsible for drafting and reviewing the purchase contract. The typical flow is for the seller's
         attorney to draft the purchase contract, and then reach out to your attorney for final review.
        </>,
      },
    ],
    [offer?.property_state]
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header/>
        <Skeleton height={56} />
      </>
    );
  }

  return <BuyerAttorneyComponent onContinue={onContinue} offer={offer} />;
};
