import React from 'react';
import { useIdentityContext, User } from 'react-netlify-identity';

function getFreshJWTNullSafe(getFreshJWT: () => Promise<string> | undefined, user: User | undefined): void {
  if (!user?.jwt) {
    // On user signup, we don't have jwt method yet. 
    // https://github.com/netlify/gotrue-js/blob/2ed0a7a47415f0782b1617d98712f883538618c7/src/index.js#L66
    return;
  }
  getFreshJWT();
}

// 1s -> 1min -> 30mins
const THIRTY_MINUTES = 1000 * 60 * 30;
export const useRefreshJWT = () => {
  const { getFreshJWT, user } = useIdentityContext();
  React.useEffect(() => {
    getFreshJWTNullSafe(getFreshJWT, user);
    setInterval(() => {
      getFreshJWTNullSafe(getFreshJWT, user);
    }, THIRTY_MINUTES);
  }, [getFreshJWT, user]);
};

export const RefreshProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useRefreshJWT();
  return <>{children}</>;
};
