import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from '@material-ui/core';
import { booleanToYesNoString, yesNoStringToBoolean } from '../../../lib/types';
import { NextButton } from '../FormPage';

const HomeSaleOnTheMarketComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const formik = useFormik({
    initialValues: {
      current_home_listed: booleanToYesNoString(offer.current_home_listed),
    },
    onSubmit: async (values) => {
      const updatedFields = {
        current_home_listed: yesNoStringToBoolean(values.current_home_listed),
      };
      update({
        offerId,
        offer: {
          ...offer,
          ...updatedFields,
        },
        updatedFields,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <RadioGroup
          aria-label="Have you listed your home for sale on the market?"
          {...formik.getFieldProps('current_home_listed')}
        >
          <FormControlLabel
            style={{ marginBottom: 28 }}
            value={'yes'}
            control={
              <Radio color="primary" style={{ transform: 'scale(1.2)' }} />
            }
            label={
              <Typography variant="h6" style={{ marginLeft: 4 }}>
                Yes
              </Typography>
            }
          />
          <FormControlLabel
            value={'no'}
            control={
              <Radio color="primary" style={{ transform: 'scale(1.2)' }} />
            }
            label={
              <Typography variant="h6" style={{ marginLeft: 4 }}>
                No
              </Typography>
            }
          />
        </RadioGroup>
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <Typography variant="h5" style={{ marginBottom: 48, fontWeight: 'bold' }}>
      Have you listed your home for sale on the&nbsp;market?
    </Typography>
  </>
);

export const HomeSaleOnTheMarket = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={35} />
        <Skeleton height={35} />
      </>
    );
  }

  return <HomeSaleOnTheMarketComponent onContinue={onContinue} offer={offer} />;
};
