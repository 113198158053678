import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CurrencyTextMask } from '../../../components/CurrencyTextMask';
import { createStyles, makeStyles } from '@material-ui/core';
import { SecondaryChip } from '../../../components/SecondaryChip';
import { stringToNumber } from '../../../lib/types';
import { HeaderSolo, NextButton, Tip } from '../FormPage';
import { useSetFAQ } from '../../../useSetFAQ';
import { ExternalLink } from '../../../components/ExternalLink';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      transform: 'translate(0px, -24px)',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const PropertyDownPaymentComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const {
    down_payment_percent
  } = offer;
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      down_payment_percent,
    },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  const handleChipClick = (percentage: number) => {
    return () => {
      formik.setFieldValue('down_payment_percent', percentage);
    };
  };

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <div className={classes.root}>
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="5%"
            onClick={handleChipClick(5)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="10%"
            onClick={handleChipClick(10)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="15%"
            onClick={handleChipClick(15)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="20%"
            onClick={handleChipClick(20)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="25%"
            onClick={handleChipClick(25)}
          />
        </div>
        <OutlineInputField
          {...formik.getFieldProps('down_payment_percent')}
          variant="outlined"
          label="Down Payment"
          placeholder="20"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <Tip>
          Most buyers use conventional loans and put 20% down. See FAQ for more info.
        </Tip>
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderSolo>
      How much is your down payment?
    </HeaderSolo>
  </>
);

export const PropertyDownPayment = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `What should I consider for my down payment?`,
        description: <>
          For a conventional loan, putting at least 20% down increases your chances of getting approved for a mortgage at a low rate 
          and enables you to avoid paying for <ExternalLink 
          href="https://www.consumerfinance.gov/ask-cfpb/what-is-mortgage-insurance-and-how-does-it-work-en-1953/">mortage insurance</ExternalLink>. 
          For <ExternalLink href="https://www.bankrate.com/mortgages/conventional-fha-va-mortgage/">other types of loans</ExternalLink> (FHA, VA, and USDA), 
          people often put down less. 
        </>,
      },
    ],
    []
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={56} />
      </>
    );
  }

  return <PropertyDownPaymentComponent onContinue={onContinue} offer={offer} />;
};
