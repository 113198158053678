import React from 'react';
import { Form, Input, Button, Tabs, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useIdentityContext } from 'react-netlify-identity';
import { useHistory } from 'react-router-dom';

import './Authentication/Authentication.css';

const { TabPane } = Tabs;

function useUpdate() {
  const { updateUser } = useIdentityContext();
  const update = React.useCallback(
    (password: string) => {
      return updateUser({ password });
    },
    [updateUser]
  );

  return update;
}

export const PasswordResetModal: React.FunctionComponent = () => {
  const update = useUpdate();
  const history = useHistory();

  const [isInFlight, setIsInFlight] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleDone = () => {
    history.replace('/');
  };

  const handleSubmit = async ({ password }: { password: string }) => {
    console.log('test');
    setIsInFlight(true);

    try {
      await update(password);
      setIsInFlight(false);
      handleDone();
    } catch (e) {
      const err: string = e.json.msg || e.json.error_description;
      setErrorMessage(err);
    } finally {
      setIsInFlight(false);
    }
  };

  return (
    <>
      <Modal
        width={348}
        title={null}
        visible={true}
        onOk={() => {}}
        onCancel={handleDone}
        footer={null}
      >
        <Tabs style={{ display: 'flex', width: '100%' }}>
          <TabPane tab="Set New Password" key="recover" style={{ flex: 1 }} />
        </Tabs>
        <Form initialValues={{ remember: true }} onFinish={handleSubmit}>
          <Form.Item name="password" rules={[{ required: true, message: ' ' }]}>
            <Input
              type="password"
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isInFlight}
              size="large"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
            >
              Change Password
            </Button>
          </Form.Item>
          {/* TODO(trevor.healy): Show a better error message */}
          {Boolean(errorMessage) && errorMessage}
        </Form>
      </Modal>
    </>
  );
};
