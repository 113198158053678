import React from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import { useLocation } from 'react-router-dom';
import 'url-search-params-polyfill';
import MenuAppBar from '../components/AppBar';
import Authentication from '../components/Authentication/Authentication';
import { SUPPORTED_STATES } from '../lib/constants';

export const OutsideServiceArea: React.FunctionComponent = () => {
  const locationState = useLocation().state as any;
  const state = locationState ? locationState.state : undefined;
  const { isLoggedIn } = useIdentityContext();
  return (
    <>
      <MenuAppBar/>
      <div className='container' style={{ width: '900px' }}>
        <h1 style={{ textAlign: 'center' }}>Sorry, we're unable to create an offer for your home.</h1>
        {state && <h2 style={{ textAlign: 'center', margin: '1em 0'}}>{state}</h2>}
        <p>
          Your house is not in a state we support right now, but we will soon! 
          We've recorded your intent and will notify you when your state is ready.
          If you want to try out Ferryhome, 
          go back and try one of the following states which we currently support:
        </p>
        <ul>
          {SUPPORTED_STATES.map(function(state) {
            return <li><h3>{state}</h3></li>
          })}
        </ul>
        {!isLoggedIn && <>
          <h2 style={{ textAlign: 'center', marginTop: '100px' }}>Sign up or log in to stay updated!</h2>
          <Authentication defaultSignup={true} />
        </>}
      </div>
    </>
  );
};
