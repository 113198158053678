import React, { createContext, useContext } from 'react';

export type Address = {
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  neighborhood: string;
};

export enum Categories {
  'BACKGROUND_INFORMATION' = 'BACKGROUND_INFORMATION',
}

export const categoriesInformation = {
  [Categories.BACKGROUND_INFORMATION]: {
    url: '/form/background-info',
    name: 'Background Information',
  },
};

const formDefaults = {
  [Categories.BACKGROUND_INFORMATION]: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    houseAddress: {
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
    },
  },
};

const FormContext = createContext({});

function useFormProvider() {
  const [backgroundInformation, setBackgroundInformation] = React.useState(
    formDefaults[Categories.BACKGROUND_INFORMATION]
  );

  return {
    [Categories.BACKGROUND_INFORMATION]: {
      ...backgroundInformation,
      set: setBackgroundInformation,
    },
  };
}

export function FormContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const formProvider = useFormProvider();
  return (
    <FormContext.Provider value={formProvider}>{children}</FormContext.Provider>
  );
}

export function useFormContext(): ReturnType<typeof useFormProvider> {
  // @ts-ignore
  return useContext(FormContext);
}
