import React from 'react';
import { useFormik } from 'formik';
import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import { HeaderAccompanied, NextButton, Subheader } from '../FormPage';
import { useSetFAQ } from '../../../useSetFAQ';

const IncludedExcludedItemsComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const { items_included, items_excluded } = offer;
  const formik = useFormik({
    initialValues: { items_included, items_excluded },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });
  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <OutlineInputField
          {...formik.getFieldProps('items_included')}
          variant="outlined"
          multiline
          label="Items to Include"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480, marginBottom: 72 }}
        />
        <OutlineInputField
          {...formik.getFieldProps('items_excluded')}
          variant="outlined"
          multiline
          label="Items to Exclude"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480, marginBottom: 24 }}
        />
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderAccompanied>
      What items do you want to make sure are included or excluded in the sale? (optional)
    </HeaderAccompanied>
    <Subheader>
      See FAQ for what's automatically included. If you have no special requests, you can leave this blank. 
    </Subheader>
  </>
);

export const IncludedExcludedItems = ({ onContinue }: { onContinue: () => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `What is automatically included in the sale?`,
        description: <>
          Unless the seller indicates otherwise in the contract, these are included:
          <ul>
            <li>All major appliances: stoves, refrigerators, washers and dryers</li>
            <li>Existing fixture and fittings attached to the home (e.g. built-in outdoor BBQ or spa)</li>
            <li>Electrical, mechanical, lighting, plumbing and heating fixtures</li>
            <li>Ceiling fans</li>
            <li>Fireplace inserts, gas logs, and grates</li>
            <li>Solar power systems</li>
            <li>Built-in appliances</li>
            <li>Window and door screens</li>
            <li>Awnings</li>
            <li>Shutters</li>
            <li>Window coverings (blinds)</li>
            <li>Attached floor coverings (carpet)</li>
            <li>TV antennas</li>
            <li>Satellite dishes</li>
            <li>Air coolers/conditioners</li>
            <li>Pool/spa equipment</li>
            <li>Garage door openers/remote controls</li>
            <li>Mailbox</li>
            <li>In-ground landscaping</li>
            <li>Trees/shrubs</li>
            <li>Water features and fountains</li>
            <li>Water softeners</li>
            <li>Water purifiers</li>
            <li>Security systems/alarms</li>
          </ul>
        </>
      },
      {
        header: `What is automatically not included in the sale?`,
        description: <>
          Unless the seller indicates otherwise in the contract, these are included:
          <ul>
            <li>Audio and video components (e.g. flat-screen TVs, speakers) if not directly attached to the property, even if there is a bracket attached to the property</li>
            <li>Furniture and other items secured to the property for earthquake purposes</li>
          </ul>
        </>
      },
    ],
    []
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header/>
        <Skeleton height={56} />
      </>
    );
  }

  return <IncludedExcludedItemsComponent onContinue={onContinue} offer={offer} />;
};
