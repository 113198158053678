import { useIdentityContext } from 'react-netlify-identity';
import { useQuery } from 'react-query';
import { Offer } from '../types/models/offer';
import { OfferWrapper } from '../types/models/offer-wrapper';

const axios = require('axios');

async function fetchOffer(_key: string, offerId: string, accessToken: string, offlineOffer: Offer | null) {
  // console.log('read')
  // console.log(_key + offerId)
  // console.log(accessToken)
  if (!accessToken) {
    // support offline mode
    return offlineOffer;
  }
  
  const res = await axios.get(`/.netlify/functions/read-offer/${offerId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  });
  return res.data.data as Offer;
}

async function fetchOffers(_key: string, userId: string, accessToken: string) {
  const res = await axios.get(`/.netlify/functions/read-offers-by-user-id/${userId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  });
  return res.data.data.map((offer: any) => {
    return {
      id: offer.ref['@ref'].id,
      ts: offer.ts,
      offer: offer.data,
    };
  }) as OfferWrapper[];
}

export function useReadOffer(offerId: string, offlineOffer: Offer | null) {
  const { user } = useIdentityContext();
  const accessToken = user?.token?.access_token;
  return useQuery(['offer', offerId, accessToken], async function(
    _key: string, 
    offerId: string,
    accessToken: string,
  ) {
    return fetchOffer(_key, offerId, accessToken, offlineOffer)
  }, {
    refetchOnWindowFocus: false,
    // cache data becomes invalid at this time
    // https://github.com/tannerlinsley/react-query/discussions/1685
    staleTime: 10000
  });
}

export function useReadOffersByUserId() {
  const { user } = useIdentityContext();
  const accessToken = user?.token?.access_token;
  const userId = user?.id;

  return useQuery(['offer', userId, accessToken], fetchOffers,
  {
    refetchOnWindowFocus: false,
    // cache data becomes invalid at this time
    // https://github.com/tannerlinsley/react-query/discussions/1685
    staleTime: 30000
  });
}
