import React from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import { Button } from 'antd';

import {
  Link,
  useHistory,
} from 'react-router-dom';
import { useAppContext } from '../../AppContext';
import { OfferStatus } from '../../types/models/offer';

export const Admin = () => {
  let history = useHistory();
  const { user, authedFetch } = useIdentityContext();
  const { offerId } = useAppContext();
  const userId = user?.id || '';

  const [isReadingOffer, setIsReadingOffer] = React.useState(false);
  const handleReadOffer = async () => {
    setIsReadingOffer(true);
    await authedFetch.get(`./.netlify/functions/read-offer/${offerId}`);
    setIsReadingOffer(false);
  };

  const [isSubmittingOffer, setIsSubmittingOffer] = React.useState(false);
  const [isReadingOffersByUserId, setIsReadingOffersByUserId] = React.useState(
    false
  );
  const [isSendingOfficialOffer, setIsSendingOfficialOffer] = React.useState(false);
  const [isGettingEmails, setIsGettingEmails] = React.useState(
    false
  );
  const handleSubmitOffer = async () => {
    setIsSubmittingOffer(true);
    const response = await authedFetch.post(
      `./.netlify/functions/submit-offer/${offerId}`,
      {
        body: JSON.stringify({
          data: {
            user_id: userId,
            status: OfferStatus.SUBMITTED,
            submitted_at_ts: Date.now(),
          },
        }),
      }
    );
    console.log(response);
    setIsSubmittingOffer(false);
  };
  const handleSendOfficialOffer = async () => {
    setIsSendingOfficialOffer(true);
    const response = await authedFetch.post(
      `./.netlify/functions/send-official-offer/${offerId}`,
      {
        body: JSON.stringify({})
      }
    );
    console.log(response);
    setIsSendingOfficialOffer(false);
  };


  const handleReadOffersByUserId = async () => {
    setIsReadingOffersByUserId(true);
    const response = await authedFetch.get(
      `./.netlify/functions/read-offers-by-user-id/${userId}`
    );
    console.log(response);
    setIsReadingOffersByUserId(false);
  };

  const handleGetEmailsAsCSV= async () => {
    setIsGettingEmails(true);
    const response = await authedFetch.get(
      `./.netlify/functions/admin-get-latest-emails`
    );
    console.log(response);
    setIsGettingEmails(false);
  };

  return (
    <>
      <h3 style={{ margin: '20px 0' }}>Admin tools</h3>
      <Button onClick={() => {history.push(`/create-offer`)}}>
        Create Offer
      </Button>
      <Button onClick={handleSubmitOffer} loading={isSubmittingOffer}>
        Submit offer
      </Button>
      <Button onClick={handleSendOfficialOffer} loading={isSendingOfficialOffer}>
        Send official offer
      </Button>
      <Button
        onClick={handleReadOffersByUserId}
        loading={isReadingOffersByUserId}
      >
        Read Offers by User Id
      </Button>
      <Button
        onClick={handleReadOffer}
        loading={isReadingOffer}
        disabled={!Boolean(offerId)}
      >
        Read Offer
      </Button>
      <Button
        onClick={handleGetEmailsAsCSV}
        loading={isGettingEmails}
      >
        Get Emails as CSV
      </Button>
      <br />
      <div style={{ maxWidth: 800, margin: '0 auto', marginTop: 36 }}>
        {offerId ? (
          <Link to="/form">Start Working on Offer ID: {offerId}</Link>
        ) : (
          <h3>No Offers! Create One Above!</h3>
        )}
      </div>
    </>
  );
};
