import React from 'react';
import { useAppContext } from './AppContext';
export function useSetFAQ(
  faq: Array<{ header: string; description: JSX.Element }>
) {
  const { setFAQ } = useAppContext();
  React.useEffect(() => {
    setFAQ(faq);

    return () => {
      setFAQ([]);
    };
  }, [faq, setFAQ]);

  return null;
}
