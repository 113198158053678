import React from 'react';
import { useFormik } from 'formik';
import { Button } from 'antd';
import 'url-search-params-polyfill';

import { Offer, OfferStatus } from '../../types/models/offer';
import { offerSchema, PropertyAddress } from '../../types/models/offer-validation';
import { GoogleAutocomplete } from '../Form/Address/GoogleAutocomplete';
import { OutlineInputField } from '../../components/OutlineInputField';
import './CreateOffer.css'
import { Typography } from '@material-ui/core';
import { ExternalLink } from '../../components/ExternalLink';
import { EMPTY_UUID } from '../../lib/constants';

const axios = require('axios')

function initCreateMyUrlIntent(userId: string, values: PropertyAddress): Object {
  return {
    created_at_ts: Date.now(),
    user_id: userId,
    status: OfferStatus.PENDING,
    middle_name: 'create-my-url',
    ...values,
  }
}

function generateUrl(values: PropertyAddress): string {
  var base = `app.ferryhome.com/create-offer?addr=${values.property_street_address}&city=${values.property_city}&state=${values.property_state}&zip=${values.property_postal_code}`
  if (values.property_unit_number) {
    base += `&addr2=${values.property_unit_number}`
  }
  return base.replace(/\s/g, '+')
}

const PropertyAddressComponent = ({}: {}) => {
  const [manualInput, setManualInput] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      property_street_address: null,
      property_city: null,
      property_state: null,
      property_postal_code: null,
      property_unit_number: null,
      property_county: undefined,
      property_neighborhood: undefined,
    } as PropertyAddress,
    onSubmit: async (values) => {
      var offer = offerSchema.validateSync(initCreateMyUrlIntent(EMPTY_UUID, values)) as Offer;
      console.log(offer)
      axios.post('/.netlify/functions/create-intent', { data: offer }, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })
    },
  });

  const submitButtonRef = React.useRef<HTMLElement>(null);

  return (
    formik.submitCount > 0 ? 
    <> 
      <div className="create-offer-wrapper">
        <h2 style={{ textAlign: 'center' }}>{generateUrl(formik.values)}</h2>
      </div>
    </>
    :
    <>
      <div className="create-offer-wrapper">
        <Header />
        {!manualInput && !formik.values.property_street_address && (
          <>
            <GoogleAutocomplete
              onSelect={(_address) => {
                formik.resetForm({
                  values: {
                    ..._address,
                  },
                });
                if (submitButtonRef.current) {
                  submitButtonRef.current.focus();
                }
              }}
              onChange={() => {
                formik.resetForm({
                  values: {
                    property_street_address: null,
                    property_city: null,
                    property_state: null,
                    property_postal_code: null,
                    property_unit_number: null,
                    property_neighborhood: undefined,
                    property_county: undefined,
                  },
                });
              }}
            />

            <Button onClick={() => {
                      setManualInput(true);
                      formik.values.property_street_address = '';
                      formik.values.property_city = '';
                      formik.values.property_state = '';
                      formik.values.property_postal_code = '';
                      formik.values.property_unit_number = '';
                    }}
                    type="link"
                    style={{
                      padding: 0,
                      textAlign: 'left',
                    }}>
              Enter address manually
            </Button>
          </>
        )}
        <form
          onSubmit={formik.handleSubmit}
          className="fade-in"
          style={{ display: 'flex', flexDirection: 'column' }}
          action="#"
        >
          {(manualInput || formik.values.property_street_address) && (
            <>
              <OutlineInputField
                {...formik.getFieldProps('property_street_address')}
                label="Street Address"
                variant="outlined"
                required
              />
              <OutlineInputField
                {...formik.getFieldProps('property_city')}
                label="City"
                variant="outlined"
                required
              />
              <OutlineInputField
                {...formik.getFieldProps('property_state')}
                label="State"
                variant="outlined"
                required
              />
              <OutlineInputField
                {...formik.getFieldProps('property_postal_code')}
                label="Zip Code"
                variant="outlined"
                required
              />
              <OutlineInputField
                {...formik.getFieldProps('property_unit_number')}
                label="Apt/Unit #"
                variant="outlined"
              />
            </>
          )}
          <Button
            htmlType="submit"
            type="primary"
            style={{
              height: 60,
              width: '100%',
              maxWidth: 480,
              marginTop: 48,
              fontSize: 16,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            ref={submitButtonRef}
            disabled={!formik.values.property_street_address}
          >
            Generate URL
          </Button>
          {formik.values.property_street_address &&
          <Typography variant='caption' style={{ 
            maxWidth: 480, 
            marginTop: '0.5em', 
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
            This will generate your personal checkout URL for your listing.
            By clicking the button above, you agree to our <ExternalLink href="https://ferryhome.com/terms">terms and conditions</ExternalLink>.
          </Typography>
          }
        </form>
      </div>
    </>
  );
};

const Header = () => (
  <div style={{ fontSize: 18, marginBottom: 48, fontWeight: 'bold' }}>
    Enter your address to generate your personal checkout URL!
  </div>
);

export const CreateMyUrl = () => {
  return <PropertyAddressComponent/>;
};
