import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import {
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { dateToLocalDateString, localDateStringToDate } from '../../../lib/types';
import { NextButton } from '../FormPage';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        width: '100%',
      },

      '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
        '& fieldset': {
          borderColor: '#D4DDEB',
        },
        '& input': {
          cursor: 'pointer',
        },
        '&:hover fieldset': {
          borderColor: '#005EFF',
        },
      },
    },
  })
);

const HomeSaleCloseDateComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      current_home_closing_local_date: offer.current_home_closing_local_date ? localDateStringToDate(offer.current_home_closing_local_date) : new Date(),
    },
    onSubmit: async (values) => {
      const updatedFields = {
        current_home_closing_local_date: dateToLocalDateString(values.current_home_closing_local_date),
      };
      update({
        offerId,
        offer: {
          ...offer,
          ...updatedFields,
        },
        updatedFields,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              margin="normal"
              label="Close Date"
              variant="dialog"
              inputVariant="outlined"
              onChange={(date) => {
                formik.setFieldValue('current_home_closing_local_date', date);
              }}
              value={formik.values.current_home_closing_local_date}
            />
          </MuiPickersUtilsProvider>
        </div>
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <Typography variant="h5" style={{ marginBottom: 48, fontWeight: 'bold' }}>
      When is the closing date for your current&nbsp;home?
    </Typography>
  </>
);

export const HomeSaleCloseDate = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={56} />
      </>
    );
  }

  return <HomeSaleCloseDateComponent onContinue={onContinue} offer={offer} />;
};
