import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export function MobileFormMenu(props: any) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link to="/form/property">
          <ListItem button key={'Basic Info'}>
            <ListItemIcon>{<MailIcon />}</ListItemIcon>

            <ListItemText primary={'Basic Info'} />
          </ListItem>
        </Link>
        <Link to="/form/contingency">
          <ListItem button key={'Contingencies'}>
            <ListItemIcon>{<MailIcon />}</ListItemIcon>

            <ListItemText primary={'Contingencies'} />
          </ListItem>
        </Link>
        <Link to="/form/closing-costs">
          <ListItem button key={'Closing Costs'}>
            <ListItemIcon>{<MailIcon />}</ListItemIcon>

            <ListItemText primary={'Closing Costs'} />
          </ListItem>
        </Link>
        <Link to="/form/additional-terms">
          <ListItem button key={'Additional Terms'}>
            <ListItemIcon>{<MailIcon />}</ListItemIcon>

            <ListItemText primary={'Additional Terms'} />
          </ListItem>
        </Link>
        <Link to="/form/contact">
          <ListItem button key={'Contact Info'}>
            <ListItemIcon>{<MailIcon />}</ListItemIcon>

            <ListItemText primary={'Contact Info'} />
          </ListItem>
        </Link>
        <Link to="/form/review-offer">
          <ListItem button key={'Review Offer'}>
            <ListItemIcon>{<MailIcon />}</ListItemIcon>

            <ListItemText primary={'Review Offer'} />
          </ListItem>
        </Link>
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      {(['left'] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          {props.children({ toggleDrawer })}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
