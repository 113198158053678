import { User } from "react-netlify-identity";
import { Offer } from "../types/models/offer";

const axios = require('axios');

const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function generateHeaders(user: User): Object {
  const accessToken = user?.token?.access_token;
  console.log(accessToken)
  return {
    headers: {
      ...JSON_HEADERS,
      Authorization: `Bearer ${accessToken}`,
    }
  }
}

export async function createOffer(
  user: User,
  data: Object): Promise<any> {
  return axios.post(`/.netlify/functions/create-offer`, { data: data }, generateHeaders(user));
}

export async function submitOffer(
  user: User,
  offerId: string,
  data: Offer): Promise<any> {
  return axios.post(`/.netlify/functions/submit-offer/${offerId}`, { data: data }, generateHeaders(user));
}