import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from '@material-ui/core';
import { NextButton } from '../FormPage';

const ClosingCostsEscrowAgentComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const { escrow_agent_fee_breakdown } = offer;
  const formik = useFormik({
    initialValues: { escrow_agent_fee_breakdown },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <RadioGroup
          aria-label="How do you want the escrow agent feeds to be paid?"
          {...formik.getFieldProps('escrow_agent_fee_breakdown')}
        >
          <FormControlLabel
            style={{ marginBottom: 28 }}
            value={'EACH_PAY_OWN'}
            control={
              <Radio color="primary" style={{ transform: 'scale(1.2)' }} />
            }
            label={
              <Typography variant="h6" style={{ marginLeft: 4 }}>
                We Each Pay Our Own
              </Typography>
            }
          />
          <FormControlLabel
            value={'SELLER_PAYS_FULL'}
            control={
              <Radio color="primary" style={{ transform: 'scale(1.2)' }} />
            }
            label={
              <Typography variant="h6" style={{ marginLeft: 4 }}>
                Seller Pays the Full Cost
              </Typography>
            }
          />
        </RadioGroup>
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <Typography variant="h5" style={{ marginBottom: 48, fontWeight: 'bold' }}>
      How do you want the escrow agent feeds to be&nbsp;paid?
    </Typography>
  </>
);

export const ClosingCostsEscrowAgent = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={35} />
        <Skeleton height={35} />
      </>
    );
  }

  return (
    <ClosingCostsEscrowAgentComponent onContinue={onContinue} offer={offer} />
  );
};
