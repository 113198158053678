import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { BuyerInfo } from './BuyerInfo';
import { BuyerAttorney } from './BuyerAttorney';
import { SellerPocInfo } from './SellerPocInfo';
import { isBuyerAttorneyPageEnabled, isBuyerBioPageEnabled, isBuyerFinancialsPageEnabled } from '../FormConfig';
import { Offer } from '../../../types/models/offer';
import { BuyerFinancials } from './BuyerFinancials';
import { BuyerBio } from './BuyerBio';

export function ContactRouter() {
  let match = useRouteMatch();
  let history = useHistory();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/buyer-info`}>
          <BuyerInfo
            onContinue={(offer: Offer) => {
              if (isBuyerFinancialsPageEnabled(offer)) {
                return history.push(`${match.path}/buyer-financials`);
              }
              if (isBuyerBioPageEnabled(offer)) {
                return history.push(`${match.path}/buyer-bio`);
              }
              if (isBuyerAttorneyPageEnabled(offer)) {
                return history.push(`${match.path}/buyer-attorney`);
              }
              history.push(`${match.path}/seller-poc-info`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/buyer-financials`}>
          <BuyerFinancials
            onContinue={(offer: Offer) => {
              if (isBuyerBioPageEnabled(offer)) {
                return history.push(`${match.path}/buyer-bio`);
              }
              if (isBuyerAttorneyPageEnabled(offer)) {
                return history.push(`${match.path}/buyer-attorney`);
              }
              history.push(`${match.path}/seller-poc-info`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/buyer-bio`}>
          <BuyerBio
            onContinue={(offer: Offer) => {
              if (isBuyerAttorneyPageEnabled(offer)) {
                return history.push(`${match.path}/buyer-attorney`);
              }
              history.push(`${match.path}/seller-poc-info`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/buyer-attorney`}>
          <BuyerAttorney
            onContinue={() => {
              history.push(`${match.path}/seller-poc-info`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/seller-poc-info`}>
          <SellerPocInfo
            onContinue={() => {
              history.push('/form/review-offer');
            }}
          />
        </Route>
        <Route>
          <Redirect to={`${match.path}/buyer-info`} />
        </Route>
      </Switch>
    </>
  );
}
