import React, { Suspense } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { Offer } from '../../../types/models/offer';
import { isDownPaymentPageEnabled } from '../FormConfig';
import { ExpirationDatetime } from './ExpirationDatetime';
import { PropertyCloseDate } from './PropertyCloseDate';
import { PropertyDownPayment } from './PropertyDownPayment';
import { PropertyEarnestMoney } from './PropertyEarnestMoney';
import { PropertyPaymentMethod } from './PropertyPaymentMethod';
import { PropertyPrice } from './PropertyPrice';

export function PropertyRouter() {
  let match = useRouteMatch();
  let history = useHistory();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/price`}>
          <Suspense fallback={<div>loading property price</div>}>
            <PropertyPrice
              onContinue={() => {
                history.push(`${match.path}/payment-method`);
              }}
            />
          </Suspense>
        </Route>
        <Route exact path={`${match.path}/payment-method`}>
          <PropertyPaymentMethod
            onContinue={(offer: Offer) => {
              if (isDownPaymentPageEnabled(offer)) {
                history.push(`${match.path}/down-payment`);
                return
              }
              history.push(`${match.path}/earnest-money`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/down-payment`}>
          <PropertyDownPayment
            onContinue={() => {
              history.push(`${match.path}/earnest-money`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/earnest-money`}>
          <PropertyEarnestMoney
            onContinue={() => {
              history.push(`${match.path}/close-date`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/close-date`}>
          <PropertyCloseDate
            onContinue={() => {
              history.push(`${match.path}/expiration-datetime`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/expiration-datetime`}>
          <ExpirationDatetime
            onContinue={() => {
              history.push(`/form/contingency/options`);
            }}
          />
        </Route>
        <Route>
          <Redirect to={`${match.path}/price`} />
        </Route>
      </Switch>
    </>
  );
}
