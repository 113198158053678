import React from 'react';

export function useViewportHeightCorrection() {
  React.useEffect(() => {
    setViewportHeightCssProperty();

    const handleResize = () => {
      setViewportHeightCssProperty();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
}

function setViewportHeightCssProperty() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// NOTE(trevor.healy): viewport units on mobile are odd (https://css-tricks.com/the-trick-to-viewport-units-on-mobile/)
