import React from 'react';
import { useFormik } from 'formik';
import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import { HeaderAccompanied, NextButton, Subheader, Tip } from '../FormPage';
import { createStyles, InputAdornment, makeStyles } from '@material-ui/core';
import { SecondaryChip } from '../../../components/SecondaryChip';
import { ExternalLink } from '../../../components/ExternalLink';
import { useSetFAQ } from '../../../useSetFAQ';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      transform: 'translate(0px, -24px)',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const BuyerFinancialsComponent = ({
  onContinue,
  offer,
}: {
  onContinue: (offer: Offer) => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const {
    debt_to_income_ratio,
    post_closing_liquidity_years,
  } = offer;
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      debt_to_income_ratio,
      post_closing_liquidity_years,
    },
    onSubmit: async (values) => {
      const updatedOffer = {
        ...offer,
        ...values,
      }
      update({
        offerId,
        offer: updatedOffer,
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue(updatedOffer);
    },
  });

  const handleDebtToIncomeRatioChipClick = (percentage: number) => {
    return () => {
      formik.setFieldValue('debt_to_income_ratio', percentage);
    };
  };

  const handlePostClosingLiquidityYearsChipClick = (years: number) => {
    return () => {
      formik.setFieldValue('post_closing_liquidity_years', years);
    };
  };

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <div className={classes.root}>
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="20%"
            onClick={handleDebtToIncomeRatioChipClick(20)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="25%"
            onClick={handleDebtToIncomeRatioChipClick(25)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="30%"
            onClick={handleDebtToIncomeRatioChipClick(30)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="35%"
            onClick={handleDebtToIncomeRatioChipClick(35)}
          />
        </div>
        <OutlineInputField
          {...formik.getFieldProps('debt_to_income_ratio')}
          variant="outlined"
          label="Debt-to-Income Ratio"
          placeholder="25"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <Tip>
          NYC co-ops are typically looking for a ratio between <ExternalLink
          href="https://hauseit.medium.com/what-are-the-debt-to-income-dti-ratio-requirements-for-a-nyc-co-op-311cb021d7bc">
            25-30%
          </ExternalLink>. See FAQ for more info.
        </Tip>
        <div className={classes.root} style={{ marginTop: '5em' }}>
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="1 year"
            onClick={handlePostClosingLiquidityYearsChipClick(1)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="1.5"
            onClick={handlePostClosingLiquidityYearsChipClick(1.5)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="2"
            onClick={handlePostClosingLiquidityYearsChipClick(2)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="3"
            onClick={handlePostClosingLiquidityYearsChipClick(3)}
          />
        </div>
        <OutlineInputField
          {...formik.getFieldProps('post_closing_liquidity_years')}
          variant="outlined"
          label="Post-Closing Liquidity"
          placeholder="2.5"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <InputAdornment position="start">years</InputAdornment>,
          }}
        />
        <Tip>
          NYC co-ops are typically looking for <ExternalLink
          href="https://www.hauseit.com/post-closing-liquidity-definition-nyc/">
            at least 1-2 years
          </ExternalLink> of liquidity. See FAQ for more info.
        </Tip>
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderAccompanied>
      If you are buying a co-op, what are your financial numbers?
    </HeaderAccompanied>
    <Subheader>
      Make sure to have your <ExternalLink 
      href="https://blocksandlots.com/wp-content/uploads/2020/12/REBNY-Financial-Statement.pdf">
        REBNY form
      </ExternalLink> ready. Skip if you're not buying a co-op.
    </Subheader>
  </>
);

export const BuyerFinancials = ({ onContinue }: { onContinue: (offer: Offer) => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `What's a co-op, and why do I have to provide this information?`,
        description: <>
          <ExternalLink href="https://streeteasy.com/blog/what-is-a-co-op-apartment-nyc-guide/">
            Co-ops</ExternalLink> are buildings that are corporations. The owner of each unit
            owns equity in the corporation, and there's a board that manages the building. 
            <br/><br/>
            These boards have a strict application process for reviewing your financials. 
            This is because it's important for each homeowner to be financially sound:
            if one shareholder falls into debt, the entire building has to cover. 
        </>,
      },
      {
        header: `What's the REBNY form, and why do I need it?`,
        description: <>
          <ExternalLink href="https://hauseit.medium.com/sample-rebny-financial-statement-for-nyc-77d3a5e219a0">
            The REBNY form</ExternalLink> is a personal "balance sheet" used by listing agents
            and co-op boards to compare your financial strength with other applicants. 
            It's part of virtually all co-op application processes,
            and you can fill out the form <ExternalLink href="https://blocksandlots.com/wp-content/uploads/2020/12/REBNY-Financial-Statement.pdf">
              here
            </ExternalLink>.
        </>,
      },
      {
        header: `What's debt-to-income ratio, and how do I calculate it?`,
        description: <>
          <ExternalLink href="https://hauseit.medium.com/what-are-the-debt-to-income-dti-ratio-requirements-for-a-nyc-co-op-311cb021d7bc">
            Debt-to-income ratio</ExternalLink> is the percentage of your monthly income 
            that goes towards fixed housing costs, i.e. mortgage and co-op payments.
            To calculate it, take your projected monthly housing cost 
            and divide it by your monthly income, e.g. $3000 monthly mortgage and co-op payments
            divided by $12,000 monthly income would be a 25% debt-to-income ratio.
        </>,
      },
      {
        header: `What's post-closing liquidity, and how do I calculate it?`,
        description: <>
          <ExternalLink href="https://www.hauseit.com/post-closing-liquidity-definition-nyc/">
            Post-closing liquidity</ExternalLink> is the amount of liquid funds you have after closing. 
            It's measured in number of years of fixed housing cost, i.e. mortgage and co-op payments, you can cover. For example,
            if your annual fixed housing cost is $40,000, and you have $100,000 of cash leftover 
            after closing, then your post-closing liquidity in years is 2.5 ($100,000 / $40,000).
        </>,
      },
    ],
    []
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header/>
        <Skeleton height={56} />
      </>
    );
  }

  return <BuyerFinancialsComponent onContinue={onContinue} offer={offer} />;
};
