export enum PayMethod {
  LOAN = 'LOAN',
  CASH = 'CASH',
}

export enum PreapprovalType {
  PREQUALIFIED = 'PREQUALIFIED',
  PREAPPROVAL = 'PREAPPROVAL',
  UNDERWRITTEN = 'UNDERWRITTEN',
}

export enum LoanType {
  CONVENTIONAL = 'CONVENTIONAL',
  FHA = 'FHA',
  VA = 'VA',
  OTHER = 'OTHER',
}

export enum LoanRateType {
  FIXED = 'FIXED',
  ARM = 'ARM',
  UNKNOWN = 'UNKNOWN',
}

export enum TitleInsuranceBreakdown {
  HALF_HALF = 'HALF_HALF',
  SELLER_PAYS_FULL = 'SELLER_PAYS_FULL',
}

export enum EscrowAgentFeeBreakdown {
  EACH_PAY_OWN = 'EACH_PAY_OWN',
  SELLER_PAYS_FULL = 'SELLER_PAYS_FULL',
}

export enum OfferStatus {
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

// TODO: Cleanup based on the latest form
export interface Offer {
  // property terms
  property_street_address: string | null;
  property_city: string | null;
  property_state: string | null;
  property_postal_code: string | null;
  property_unit_number: string | null;
  property_id: string | null;
  property_county?: string | null; // can be inconsistent, just for auditing 
  property_neighborhood?: string | null; // can be inconsistent, just for auditing

  // basic terms
  offer_price: number | null;
  pay_method: PayMethod | null;
  down_payment_percent: number | null;
  earnest_money_percent: number | null;
  closing_local_date: string | null; // yyyy-mm-dd
  expiration_local_datetime: string | null; // yyyy-mm-dd hh:MMZ

  // contingencies selection
  financing_contingency: boolean | null;
  appraisal_contingency: boolean | null;
  home_sale_contingency: boolean | null;
  inspection_contingency: boolean | null;

  // financing contingencies
  preapproval_type: PreapprovalType | null;
  loan_type: LoanType | null;
  loan_rate_type: LoanRateType | null;
  max_acceptable_interest_rate: number | null;
  max_acceptable_mortgage_points: number | null;

  // home sale contingencies
  current_home_offer_accepted: boolean | null;
  current_home_closing_local_date: string | null; // yyyy-mm-dd
  current_home_listed: boolean | null;
  remove_financing_and_home_sale_contingencies_on_backup_offer_allowed:
    | boolean
    | null;

  // closing costs
  home_warranty_seller_paid: boolean | null;
  title_insurance_seller_paid: boolean | null;
  escrow_agent_fees_seller_paid: boolean | null;
  transfer_taxes_and_fees_seller_paid: boolean | null;

  max_home_warranty_policy_price: number | null;
  title_insurance_policy_breakdown: TitleInsuranceBreakdown | null;
  escrow_agent_fee_breakdown: EscrowAgentFeeBreakdown | null;

  county_transfer_tax_or_fee_seller_paid: boolean | null;
  city_transfer_tax_or_fee_seller_paid: boolean | null;
  any_private_transfer_fee_seller_paid: boolean | null;
  hoa_transfer_fee_seller_paid: boolean | null;
  hoa_fees_for_doc_prep_seller_paid: boolean | null;

  septic_inspection_seller_paid: boolean | null;
  well_inspection_seller_paid: boolean | null;
  property_boundary_survey_seller_paid: boolean | null;

  // additional terms
  new_home_primary_residence: boolean | null;
  preferred_title_company_name: string | null;
  items_included: string | null;
  items_excluded: string | null;
  rent_back_allowed: boolean | null;
  more_than_thirty_days_rent_back_allowed: boolean | null;
  loss_limited_to_earnest_money: boolean | null;
  dispute_limited_to_arbitration: boolean | null;
  other_terms: string | null;
  buyer_bio: string | null;

  // contact info
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: string | null;
  street_address: string | null;
  unit_number: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  country: string | null;

  has_cobuyer?: boolean | null;
  cobuyer_first_name?: string | null;
  cobuyer_last_name?: string | null;

  debt_to_income_ratio?: number | null;
  post_closing_liquidity_years?: number | null;

  buyer_attorney_name_and_cert: string | null;
  buyer_attorney_title_and_office: string | null;
  buyer_attorney_street_address: string | null;
  buyer_attorney_street_address_2: string | null;
  buyer_attorney_city: string | null;
  buyer_attorney_state: string | null;
  buyer_attorney_postal_code: string | null;
  buyer_attorney_tel: string | null;
  buyer_attorney_fax: string | null;
  buyer_attorney_email: string | null;

  // documents
  preapproval_letter?: File;
  personal_letter?: File;
  proof_of_funds?: File;

  // recipient info
  recipient_first_name: string | null;
  recipient_last_name: string | null;
  recipient_email: string | null;
  recipient_id: string | null;

  // metadata
  user_id: string;
  status: OfferStatus;
  created_at_ts: number; 
  submitted_at_ts?: number;
}