import React from "react";
import { FormikErrors, FormikValues } from 'formik';
import { Button, Checkbox } from 'antd';
import { Typography } from "@material-ui/core";

type EmptyProps = {};

// HeaderSolo is for when there's a standalone header (no subheader)
export const HeaderSolo: React.FC<EmptyProps> = ({
  children
}) => {
  return <Typography variant="h5" style={{ marginBottom: 48, fontWeight: 'bold' }}>
    {children}
  </Typography>
}

// HeaderAccompanied is for when there's a header accompanied with a subheader
export const HeaderAccompanied: React.FC<EmptyProps> = ({
  children
}) => {
  return <Typography variant="h5" style={{ marginBottom: 12, fontWeight: 'bold' }}>
    {children}
  </Typography>
}

// Subheader should be used with HeaderAccompanied
export const Subheader: React.FC<EmptyProps> = ({
  children
}) => {
  return <Typography variant="subtitle2" style={{ marginBottom: 48 }}>
    {children}
  </Typography>
}

// Tip is for adding a tip right below a field
export const Tip: React.FC<EmptyProps> = ({
  children
}) => {
  return <Typography variant="caption" style={{ marginTop: -5, maxWidth: 720 }}>
    Tip: {children}
  </Typography>
}

type ButtonProps = {
  loading: boolean;
}

// NextButton is for moving from one page to the next page
export const NextButton: React.FC<ButtonProps> = ({
  loading,
}) => {
  return <Button
    htmlType="submit"
    type="primary"
    style={{
      height: 60,
      width: '100%',
      maxWidth: 480,
      marginTop: 36,
      fontSize: 16,
      borderRadius: 48,
    }}
    loading={loading}
  >
    Next
  </Button>
}

type CheckboxButtonProps = {
  name: string;
  header: React.ReactNode;
  subheader: React.ReactNode;
  values: FormikValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<FormikValues>> | Promise<void>; 
}

export const CheckboxButton: React.FC<CheckboxButtonProps> = (props) => {
  const value = props.values[props.name];
  const handleClick = () => {
    props.setFieldValue(props.name, !value);
  };

  return (
    <div>
      <Button
        {...props}
        style={{
          padding: '16px 24px',
          height: 'fit-content',
          width: '100%',
          border: value && '1px solid #99A6E4',
          margin: '8px 0px',
          backgroundColor: value && '#F1F6FE',
        }}
        onClick={handleClick}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'grid', gridGap: 8, textAlign: 'left', maxWidth: '80%', whiteSpace: 'normal' }}>
            <div style={{ fontSize: 14, fontWeight: 'bold', color: '#505971' }}>
              {props.header}
            </div>
            <div
              style={{ fontSize: 14, fontWeight: 'normal', color: '#929397' }}
            >
              {props.subheader}
            </div>
          </div>
          <div>
            <Checkbox
              tabIndex={-1}
              checked={value}
              style={{ transform: 'scale(1.5)', pointerEvents: 'none' }}
            />
          </div>
        </div>
      </Button>
    </div>
  );
}
