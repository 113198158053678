import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { HomeSaleCloseDate } from './HomeSaleCloseDate';
import { HomeSaleOnTheMarket } from './HomeSaleOnTheMarket';
import { OfferAccepted } from './OfferAccepted';

export function HomeSaleContingencyRouter() {
  let match = useRouteMatch();
  let history = useHistory();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/offer-accepted`}>
          <OfferAccepted
            onContinue={() => {
              history.push(`${match.path}/closing-date`);
            }}
          />
        </Route>

        <Route exact path={`${match.path}/closing-date`}>
          <HomeSaleCloseDate
            onContinue={() => {
              history.push(`${match.path}/on-the-market`);
            }}
          />
        </Route>

        <Route exact path={`${match.path}/on-the-market`}>
          <HomeSaleOnTheMarket
            onContinue={() => {
              history.push('/form/closing-costs');
            }}
          />
        </Route>
        <Route>
          <Redirect to={`${match.path}/offer-accepted`} />
        </Route>
      </Switch>
    </>
  );
}
