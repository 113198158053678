import React from "react";

type ExternalLinkProps = {
  href: string;
};

// ExternalLink is a wrapper around <a> for external links.
// https://rules.sonarsource.com/html/RSPEC-5148
export const ExternalLink: React.FC<ExternalLinkProps> = ({
  href,
  children
}) => {
  return <a href={href} target="_blank" rel="noopener">{children}</a>
}