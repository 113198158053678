import React from 'react';
import { Modal } from 'antd';
import Authentication from './Authentication';

import { useAppContext } from '../../AppContext';

export const AuthenticationModal: React.FunctionComponent = () => {
  const { isAuthModalOpen, setIsAuthModalOpen } = useAppContext();

  return (
    <>
      <Modal
        width={448}
        title={null}
        visible={isAuthModalOpen}
        onOk={() => {}}
        onCancel={() => setIsAuthModalOpen((x) => !x)}
        footer={null}
      >
        <Authentication />
      </Modal>
    </>
  );
};
