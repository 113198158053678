import React from 'react';
import { useIdentityContext } from 'react-netlify-identity';

import { useHistory, useLocation } from 'react-router-dom';
import { PasswordResetModal } from '../components/PasswordResetModal';

export function CatchNetlifyRecoveryNullComponent() {
  const {
    param: { token, type },
  } = useIdentityContext();

  const { replace } = useHistory();
  const { pathname } = useLocation();

  // NOTE(trevor.healy): Must check for pathname or else it will infinitely loop
  if (token && type === 'recovery' && pathname === '/') {
    replace(`/recovery`, { token });
  }

  return null;
}

// TODO(trevor.healy): Not sure what recoverAccount does, it's used in the docs,
// but I'm not convinced it's actually doing anything / necessary for the reset.
export function RecoveryPage() {
  const { recoverAccount } = useIdentityContext();
  const {
    location: { state },
  } = useHistory();

  // @ts-ignore
  const [token] = React.useState(state?.token);
  console.log('token:', token);

  React.useEffect(() => {
    recoverAccount();
  }, [recoverAccount]);

  return <PasswordResetModal />;
}
