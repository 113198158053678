import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { getPropertyFullAddress } from '../../../lib/address';
import { Offer } from '../../../types/models/offer';

const useClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 12,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: 12,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 24,
      },
    },
    header: {
      marginBottom: theme.spacing(4),
      color: '#434B65',
      fontWeight: 'bold',
    },
  })
);

type PropertyOverviewHeaderProps = {
  offer: Offer;
};

export function PropertyOverviewHeader({ offer }: PropertyOverviewHeaderProps) {
  const classes = useClasses();

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        {getPropertyFullAddress(offer)}
      </Typography>
    </div>
  );
}
