import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { Offer } from '../../../types/models/offer';
import { isFinancingContingencyPageEnabled } from '../FormConfig';
import { ContingencyFinancing } from './ContingencyFinancing';
import { ContingencyOptions } from './ContingencyOptions';

export function ContingencyRouter() {
  let match = useRouteMatch();
  let history = useHistory();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/options`}>
          <ContingencyOptions
            onContinue={(offer: Offer) => {
              if (isFinancingContingencyPageEnabled(offer)) {
                return history.push(`${match.path}/financing`)
              }
              return history.push('/form/additional-terms');
            }}
          />
        </Route>
        <Route exact path={`${match.path}/financing`}>
          <ContingencyFinancing
            onContinue={() => {
              history.push('/form/additional-terms');
            }}
          />
        </Route>
        {/* <Route exact path={`${match.path}/home-sale`}>
          <ContingencyHomeSale
            onContinue={() => {
              history.push(`${match.path}/home-sale`);
            }}
          />
        </Route> */}
        <Route>
          <Redirect to={`${match.path}/options`} />
        </Route>
      </Switch>
    </>
  );
}
