import React from 'react';
import { useFormik } from 'formik';
import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import { HeaderAccompanied, HeaderSolo, NextButton, Subheader, Tip } from '../FormPage';

const OtherTermsComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const { other_terms } = offer;
  const formik = useFormik({
    initialValues: { other_terms },
    onSubmit: async (values) => {
      const updatedOffer = {
        ...offer,
        ...values,
      }
      update({
        offerId,
        offer: updatedOffer,
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });
  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <OutlineInputField
          {...formik.getFieldProps('other_terms')}
          variant="outlined"
          multiline
          label="Other Terms"
          placeholder=""
          InputProps={{
            startAdornment: <div />,
            endAdornment: <div />
          }}
          style={{ maxWidth: 480 }}
        />
        <Tip>This language goes into your offer verbatim, so make sure you've drafted this carefully.</Tip>
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderSolo>
      What other terms do you want to include in your offer? (optional)
    </HeaderSolo>
  </>
);

export const OtherTerms = ({ onContinue }: { onContinue: () => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  if (response.isFetching || !offer) {
    return (
      <>
        <Header/>
        <Skeleton height={56} />
      </>
    );
  }

  return <OtherTermsComponent onContinue={onContinue} offer={offer} />;
};
