import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { OutlineInputField } from '../../../components/OutlineInputField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { SecondaryChip } from '../../../components/SecondaryChip';
import { NextButton } from '../FormPage';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      transform: 'translate(0px, -24px)',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const ClosingCostsHomeWarrantyPolicyComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const { max_home_warranty_policy_price } = offer;
  const classes = useStyles();

  const formik = useFormik({
    initialValues: { max_home_warranty_policy_price },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  const handleChipClick = (value: number) => {
    return () => {
      formik.setFieldValue('max_home_warranty_policy_price', value.toString());
    };
  };

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <div className={classes.root}>
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="$500"
            onClick={handleChipClick(500)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="$600"
            onClick={handleChipClick(600)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="$700"
            onClick={handleChipClick(700)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="$800"
            onClick={handleChipClick(800)}
          />
          <SecondaryChip
            variant="outlined"
            color="primary"
            clickable
            label="$900"
            onClick={handleChipClick(900)}
          />
        </div>

        <OutlineInputField
          {...formik.getFieldProps('max_home_warranty_policy_price')}
          variant="outlined"
          label="Home Warranty Policy Price"
          placeholder="0"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <Typography variant="h5" style={{ marginBottom: 48, fontWeight: 'bold' }}>
      What is the maximum price of the home warranty policy the seller will
      pay&nbsp;for?
    </Typography>
  </>
);

export const ClosingCostsHomeWarrantyPolicy = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={56} />
      </>
    );
  }

  return (
    <ClosingCostsHomeWarrantyPolicyComponent
      onContinue={onContinue}
      offer={offer}
    />
  );
};
