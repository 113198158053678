import React from 'react';
import { useFormik } from 'formik';
import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { CheckboxButton, HeaderAccompanied, NextButton, Subheader, Tip } from '../FormPage';
import { ExternalLink } from '../../../components/ExternalLink';
import { useSetFAQ } from '../../../useSetFAQ';
import { getStateCode } from '../../../lib/address';

const ContingencyOptionsComponent = ({
  onContinue,
  offer,
}: {
  onContinue: (offer: Offer) => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const formik = useFormik({
    initialValues: {
      // defaultChecked not working so setting presets here
      financing_contingency: offer.financing_contingency == null ? true : offer.financing_contingency,
      appraisal_contingency: offer.appraisal_contingency == null ? true : offer.appraisal_contingency,
      inspection_contingency: offer.inspection_contingency == null ? true : offer.inspection_contingency,
      home_sale_contingency: offer.home_sale_contingency == null ? false : offer.home_sale_contingency,
    },
    onSubmit: async (values) => {
      const updatedOffer = {
          ...offer,
          ...values,
      }
      update({
        offerId,
        offer: updatedOffer,
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue(updatedOffer);
    },
  });
  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <CheckboxButton
          name="financing_contingency"
          header={'Financing'}
          subheader={'Recommended if you are buying with a loan.'}
          values={formik.values}
          setFieldValue={formik.setFieldValue}
        />
        <CheckboxButton
          name="appraisal_contingency"
          header={'Appraisal'}
          subheader={'Recommended if you are buying with a loan.'}
          values={formik.values}
          setFieldValue={formik.setFieldValue}
        />
        <CheckboxButton
          name="inspection_contingency"
          header={'Inspection'}
          subheader={"Recommended if you haven't done a strong pre-inspection."}
          values={formik.values}
          setFieldValue={formik.setFieldValue}
        />
        <CheckboxButton
          name="home_sale_contingency"
          header={'Home Sale'}
          subheader={'Recommended if you need to sell your prior home to buy this one.'}
          values={formik.values}
          setFieldValue={formik.setFieldValue}
        />
        <div style={{ marginBottom: 24 }} />
        <Tip>
          Sellers like less contingencies, but you should <ExternalLink 
          href="https://www.millionacres.com/real-estate-market/homebuying/should-you-waive-contingencies-to-win-a-bidding-war/">
            carefully
          </ExternalLink> weigh your options.
        </Tip>
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderAccompanied>
      What contingencies do you want to add to your offer?
    </HeaderAccompanied>
    <Subheader>
      Contingencies enable you to back out of a contract and recoup your deposit if they do not pass.
    </Subheader>
  </>
);

export const ContingencyOptions = ({ onContinue }: { onContinue: (offer: Offer) => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `What is a financing contingency?`,
        description: <>
          This <ExternalLink
          href="https://www.investopedia.com/articles/personal-finance/102913/contingency-clauses-home-purchase-contracts.asp">
            contingency
          </ExternalLink> enables you to back out and get your earnest money back if your loan does not get finalized in time.
          {getStateCode(String(offer?.property_state)) != 'NY' && <>
            <br/><br/>
            This contract gives you 21 days to exercise this contingency. Email support@ferryhome.com if you want a different expiration date.
          </> }
        </>,
      },
      {
        header: `What is an appraisal contingency?`,
        description: <>
          This <ExternalLink
          href="https://www.investopedia.com/articles/personal-finance/102913/contingency-clauses-home-purchase-contracts.asp">
            contingency
          </ExternalLink> enables you to back out and get your earnest money back if an independent valuation of the home (aka appraisal) 
          comes out below your offer. Note if this happens, you can also try to negotiate with the seller to adjust the price 
          or simply close the <ExternalLink
          href="https://www.nerdwallet.com/article/mortgages/appraisal-gap">appraisal gap</ExternalLink>.
          {getStateCode(String(offer?.property_state)) != 'NY' && <>
            <br/><br/>
            This contract gives you 17 days to exercise this contingency. Email support@ferryhome.com if you want a different expiration date.
          </> }
        </>,
      },
      {
        header: `What is an inspection contingency?`,
        description: <>
          This <ExternalLink
          href="https://www.investopedia.com/articles/personal-finance/102913/contingency-clauses-home-purchase-contracts.asp">
            contingency
          </ExternalLink> enables you to back out and get your earnest money back if a professional inspection finds issues. Note if this happens,
          you can also try to negotiate with the seller for repairs or a concession. 
          {getStateCode(String(offer?.property_state)) != 'NY' && <>
            <br/><br/>
            This contract gives you 17 days to exercise this contingency. Email support@ferryhome.com if you want a different expiration date.
          </> }
        </>,
      },
      {
        header: `What is a pre-inspection?`,
        description: <>
          A <ExternalLink
          href="https://www.pickettstreet.com/blog/2016/05/20/the-pros-and-cons-of-a-pre-inspection/">
            pre-inspection
          </ExternalLink> is an inspection before making the offer, which can be done by the seller and/or the buyer.
          This can give you more confidence in waiving the inspection contingency in your offer, 
          but a pre-inspection may not be as thorough as the inspections done after you are under contract.
        </>,
      },
      {
        header: `What is a home sale contingency?`,
        description: <>
          This <ExternalLink
          href="https://www.investopedia.com/articles/personal-finance/102913/contingency-clauses-home-purchase-contracts.asp">
            contingency
          </ExternalLink> enables you to back out and get your earnest money back if you're unable to sell your prior home. 
          This is only relevant for current homeowners who want to make their purchase contingent on their last house being sold.
          {getStateCode(String(offer?.property_state)) != 'NY' && <>
            <br/><br/>
            This contract gives you 17 days to exercise this contingency. Email support@ferryhome.com if you want a different expiration date.
          </> }
        </>,
      },
    ],
    [offer?.property_state]
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header/>
        <Skeleton height={56} />
      </>
    );
  }


  return <ContingencyOptionsComponent onContinue={onContinue} offer={offer} />;
};
