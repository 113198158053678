import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer, PayMethod } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from '@material-ui/core';

import { useSetFAQ } from '../../../useSetFAQ';
import { ExternalLink } from '../../../components/ExternalLink';
import { HeaderSolo, NextButton } from '../FormPage';


const PropertyPaymentMethodComponent = ({
  onContinue,
  offer,
}: {
  onContinue: (offer: Offer) => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const formik = useFormik({
    initialValues: {
      // defaultValue not saving properly, so setting default here
      pay_method: offer.pay_method || PayMethod.LOAN,
    },
    onSubmit: async (values) => {
      const updatedOffer = {
        ...offer,
        ...values,
      }
      update({
        offerId,
        offer: updatedOffer,
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue(updatedOffer);
    },
  });

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <RadioGroup
          aria-label="gender"
          {...formik.getFieldProps('pay_method')}
        >
          <FormControlLabel
            style={{ marginBottom: 12 }}
            value={PayMethod.LOAN}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                I'm applying for a loan and will submit a preapproval letter
              </Typography>
            }
          />
          <FormControlLabel
            // style={{marginBottom: 28}}
            value={PayMethod.CASH}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                I'm paying with all cash and will submit proof of funds
              </Typography>
            }
          />
        </RadioGroup>
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderSolo>
      How will you pay for the home?
    </HeaderSolo>
  </>
);

export const PropertyPaymentMethod = ({
  onContinue,
}: {
  onContinue: (offer: Offer) => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const data = React.useMemo(
    () => [
      {
        header: `Should I pay with cash or loan?`,
        description: <>
          Sellers prefer cash offers, since they don't have to worry about the buyer's loan getting approved and thus take less time to close.
          Most buyers pay with loans though because they cannot afford to pay for the entire house cost with cash.
          <br/><br/>
          You can strengthen your loan offer by obtaining an <ExternalLink
          href="https://www.sammamishmortgage.com/guide/buying-a-home/standard-vs-underwritten-preapprovals/">underwritten preapproval letter</ExternalLink>.
        </>,
      },
      {
        header: `What's a preapproval letter, and where can I get one?`,
        description: <>
          A preapproval letter is a document from your lender or mortgage broker that states that they will likely lend you money for a home purchase.
          <br/><br/>
          We are building a list of lenders that you can work with. <ExternalLink
          href="https://www.better.com/">Better.com</ExternalLink> is a startup similar to Ferryhome that enables you to get a pre-approval letter online in minutes.
        </>,
      },
      {
        header: `What's considered proof of funds?`,
        description: <>
          Proof of funds is a document from your financial institution stating that you have the funds needed to purchase the home. Bank statements suffice for proof of funds.
        </>,
      },
    ],
    []
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={35} />
        <Skeleton height={35} />
      </>
    );
  }

  return (
    <PropertyPaymentMethodComponent onContinue={onContinue} offer={offer} />
  );
};
