import { withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

export const SecondaryChip = withStyles({
  root: {
    backgroundColor: '#e8f0fe8a',
    '&.MuiChip-clickable': {
      '&:active': {
        boxShadow: 'none',
      },
    },
    '&.MuiChip-outlinedPrimary': {
      border: '1px solid #005eff17',
    },
  },
})(Chip);
