import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import {
  FormControlLabel,
  Typography,
  Checkbox,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { NextButton } from '../FormPage';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      transform: 'translate(0px, -24px)',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const ClosingCostsRareInspectionsComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const { 
    septic_inspection_seller_paid, 
    well_inspection_seller_paid,
    property_boundary_survey_seller_paid,
  } = offer;
  const classes = useStyles();
  const formik = useFormik({
    initialValues: { septic_inspection_seller_paid, well_inspection_seller_paid, property_boundary_survey_seller_paid },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <div className={classes.root}>
          <Typography variant="subtitle1">(Select all that apply)</Typography>
        </div>
        <FormControlLabel
          style={{ marginBottom: 28 }}
          control={
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.2)' }}
              {...formik.getFieldProps('septic_inspection_seller_paid')}
              checked={Boolean(formik.values.septic_inspection_seller_paid)}
            />
          }
          label={
            <Typography variant="h6" style={{ marginLeft: 4 }}>
              County Transfer Taxes or Fees
            </Typography>
          }
        />
        <FormControlLabel
          style={{ marginBottom: 28 }}
          control={
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.2)' }}
              {...formik.getFieldProps('well_inspection_seller_paid')}
              checked={Boolean(formik.values.well_inspection_seller_paid)}
            />
          }
          label={
            <Typography variant="h6" style={{ marginLeft: 4 }}>
              City Transfer Taxes or Fees
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.2)' }}
              {...formik.getFieldProps('property_boundary_survey_seller_paid')}
              checked={Boolean(
                formik.values.property_boundary_survey_seller_paid
              )}
            />
          }
          label={
            <Typography variant="h6" style={{ marginLeft: 4 }}>
              Any Private Transfer Fees
            </Typography>
          }
        />
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <Typography variant="h5" style={{ marginBottom: 48, fontWeight: 'bold' }}>
      Do you want the seller to pay for any of these rare&nbsp;inspections?
    </Typography>
  </>
);

export const ClosingCostsRareInspections = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={35} />
        <Skeleton height={35} />
      </>
    );
  }

  return (
    <ClosingCostsRareInspectionsComponent
      onContinue={onContinue}
      offer={offer}
    />
  );
};
