import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { LoanRateType, LoanType, Offer, PreapprovalType } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { HeaderSolo, NextButton } from '../FormPage';
import { ExternalLink } from '../../../components/ExternalLink';
import { useSetFAQ } from '../../../useSetFAQ';
import { OutlineInputField } from '../../../components/OutlineInputField';
import { isExtraFinancingContingencyDetailEnabled } from '../FormConfig';

const ContingencyFinancingComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const {
    loan_type,
    preapproval_type,
    loan_rate_type,
    max_acceptable_interest_rate,
  } = offer;
  const formik = useFormik({
    initialValues: {
      loan_type,
      preapproval_type,
      loan_rate_type,
      max_acceptable_interest_rate,
    },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer,
      });
      onContinue();
    },
  });

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <Typography variant="h6" style={{ marginTop: 20, marginBottom: 20 }}>
          What loan type are you applying for?
        </Typography>
        <RadioGroup
          aria-label="loan_type"
          {...formik.getFieldProps('loan_type')}
        >
          <FormControlLabel
            style={{ marginBottom: 12 }}
            value={LoanType.CONVENTIONAL}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                Conventional
              </Typography>
            }
          />
          <FormControlLabel
            style={{marginBottom: 12}}
            value={LoanType.FHA}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                FHA
              </Typography>
            }
          />
          <FormControlLabel
            style={{marginBottom: 12}}
            value={LoanType.VA}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                VA
              </Typography>
            }
          />
          <FormControlLabel
            style={{marginBottom: 12}}
            value={LoanType.OTHER}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                Other
              </Typography>
            }
          />
        </RadioGroup>
        {isExtraFinancingContingencyDetailEnabled(offer) && <>
        <Typography variant="h6" style={{ marginTop: 20, marginBottom: 20 }}>
          What type of pre-approval letter do you have?
        </Typography>
        <RadioGroup
          aria-label="preapproval_type"
          {...formik.getFieldProps('preapproval_type')}
        >
          <FormControlLabel
            style={{ marginBottom: 12 }}
            value={PreapprovalType.PREQUALIFIED}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                Prequalified
              </Typography>
            }
          />
          <FormControlLabel
            style={{marginBottom: 12}}
            value={PreapprovalType.PREAPPROVAL}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                Preapproval
              </Typography>
            }
          />
          <FormControlLabel
            style={{marginBottom: 12}}
            value={PreapprovalType.UNDERWRITTEN}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                Fully underwritten preapproval
              </Typography>
            }
          />
        </RadioGroup>
        <Typography variant="h6" style={{ marginTop: 20, marginBottom: 20 }}>
          Are you applying for a fixed rate or adjustable rate loan?
        </Typography>
        <RadioGroup
          aria-label="loan_rate_type"
          {...formik.getFieldProps('loan_rate_type')}
        >
          <FormControlLabel
            style={{ marginBottom: 12 }}
            value={LoanRateType.FIXED}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                Fixed Rate
              </Typography>
            }
          />
          <FormControlLabel
            style={{marginBottom: 12}}
            value={LoanRateType.ARM}
            control={
              <Radio
                color="primary"
                style={{ transform: 'scale(1.2)' }}
              />
            }
            label={
              <Typography variant="subtitle1" style={{ marginLeft: 4 }}>
                Adjustable Rate
              </Typography>
            }
          />
        </RadioGroup>
        <Typography variant="h6" style={{ marginTop: 20, marginBottom: 20 }}>
          What is the max interest rate you would accept?
        </Typography>
        <OutlineInputField
          {...formik.getFieldProps('max_acceptable_interest_rate')}
          variant="outlined"
          label="Interest Rate"
          placeholder="3.5"
          InputProps={{
            startAdornment: <div />,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          style={{ maxWidth: 480 }}
        />
        </>}
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <HeaderSolo>
      Financing Contingency Details
    </HeaderSolo>
  </>
);

export const ContingencyFinancing = ({ onContinue }: { onContinue: () => void }) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  const faq = [
      {
        header: `What are the different loan types?`,
        description: <>
          The conventional loan is insured by private lenders and have stricter financial requirements. 
          Most of our home buyers are using conventional loans. 
          <br/><br/>
          The FHA loan is backed by the Federal Housing Administration and is a good fit for borrowers iwth lower credit score.
          <br/><br/>
          The VA loan is backed by the U.S. Department of Veteran Affairs and is a good fit for military service members, veterans, and their spouses.
          <br/><br/>
          See <ExternalLink href="https://www.bankrate.com/mortgages/conventional-fha-va-mortgage/">here</ExternalLink> for further breakdown.
        </>,
      },
  ];
  if (offer && isExtraFinancingContingencyDetailEnabled(offer)) {
    Array.prototype.push(faq, [
      {
        header: `What if I'm considering multiple loan types?`,
        description: <>
          Pick the loan type you are most likely to pursue. 
          <br/><br/>
          The contract outlines the loan type because using an FHA or VA loan requires adding an FHA/VA Amendatory clause.
          This clause enables the Buyer to request Seller to pay or correct lender-required repairs or costs. 
        </>,
      },
      {
        header: `What are the different preapproval types?`,
        description: <>
          A prequalified letter simply requires you to share your finances with a mortgage lender without verification. 
          It helps to assess your budget but don't hold much weight to sellers.
          <br/><br/>
          A preapproval letter requires you to share documents with your lender to verify your finances. 
          This letter is much more likely to get accepted by the seller and is standard for loan buyers.
          <br/><br/>
          An underwritten letter requires an underwriter to thoroughly look through your docs and check their accuracy.
          Underwriting is typically done during the closing process, so moving this upfront before the offer 
          reduces the risk to sellers tremendously and makes it almost as good as cash offers.
          <br/><br/>
          See <ExternalLink href="https://www.homelight.com/blog/buyer-pre-approval-vs-pre-qualified/">here</ExternalLink> for further breakdown.
        </>,
      },
      {
        header: `What's the difference between a fixed rate and adjustable rate loan?`,
        description: <>
          A fixed rate loan has a set rate of interest that remains unchanged throughout the life of the loan. 
          An adjustable rate loan starts out with a fixed rate but then changes over time. 
          <br/><br/>
          See <ExternalLink href="https://www.investopedia.com/mortgage/mortgage-rates/fixed-versus-adjustable-rate/">here</ExternalLink> for further breakdown.
        </>,
      },
      {
        header: `What if I'm considering both fixed and adjustable rate loans?`,
        description: <>
          Pick the loan rate type you are most likely to pursue. 
          <br/><br/>
          This contract specifies the loan rate type because if you're unable to get this loan rate type at the interest rate you want,
          you can successfully exercise your financing contingency to back out of the contract.
        </>,
      },
      {
        header: `Why should I specify the max interest rate, and how do I set it?`,
        description: <>
          The max interest rate clause is there to protect you in the case you can get a loan but only at an interest rate that's above your budget.
          You can set this to the <ExternalLink href="https://www.bankrate.com/mortgages/current-interest-rates/">market rate</ExternalLink> plus a buffer.
        </>,
      },
    ])
  };

  const data = React.useMemo(
    () => faq,
    [offer?.property_state]
  );
  useSetFAQ(data);

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={35} />
        <Skeleton height={35} />
      </>
    );
  }

  return <ContingencyFinancingComponent onContinue={onContinue} offer={offer} />;
};
