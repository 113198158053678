import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Offer } from '../../../types/models/offer';
import { isFinancingContingencyPageEnabled } from '../FormConfig';

import { FormSectionOverviewItem } from '../FormSectionOverviewItem';

const useClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 12,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: 12,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 24,
      },
    },
    header: {
      marginBottom: theme.spacing(4),
      color: '#434B65',
      fontWeight: 'bold',
    },
    itemContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
      gridGap: theme.spacing(3),
    },
  })
);

type ContingencyOverviewProps = {
  offer: Offer;
};

export function ContingencyOverview({ offer }: ContingencyOverviewProps) {
  const classes = useClasses();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        Contingencies
      </Typography>
      <div className={classes.itemContainer}>
        <FormSectionOverviewItem
          header="Financing Enabled"
          data={offer.financing_contingency}
          type='yesNo'
          route="contingency/options"
        />
        <FormSectionOverviewItem
          header="Appraisal Enabled"
          data={offer.appraisal_contingency}
          type='yesNo'
          route="contingency/options"
        />
        <FormSectionOverviewItem
          header="Inspection Enabled"
          data={offer.inspection_contingency}
          type='yesNo'
          route="contingency/options"
        />
        <FormSectionOverviewItem
          header="Home Sale Enabled"
          data={offer.home_sale_contingency}
          type='yesNo'
          route="contingency/options"
        />
        {isFinancingContingencyPageEnabled(offer) &&
        <>
          <FormSectionOverviewItem
            header="Loan Type"
            data={offer.loan_type}
            route="contingency/financing"
          />
          <FormSectionOverviewItem
            header="Loan Interest Rate"
            data={offer.max_acceptable_interest_rate}
            route="contingency/financing"
          />
        </>
        }
      </div>
    </div>
  );
}
