import React from 'react';
import { useFormik } from 'formik';

import { useAppContext } from '../../../AppContext';
import { useReadOffer } from '../../../hooks/useReadOffer';
import Skeleton from 'react-loading-skeleton';
import { Offer } from '../../../types/models/offer';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import {
  FormControlLabel,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { NextButton } from '../FormPage';

const ClosingCostsTransferTaxesComponent = ({
  onContinue,
  offer,
}: {
  onContinue: () => void;
  offer: Offer;
}) => {
  const { offerId, setOfflineOffer } = useAppContext();
  const { update, updateStatus } = useUpdateOffer();
  const { 
    county_transfer_tax_or_fee_seller_paid, 
    city_transfer_tax_or_fee_seller_paid, 
    any_private_transfer_fee_seller_paid, 
    hoa_transfer_fee_seller_paid, 
    hoa_fees_for_doc_prep_seller_paid 
  } = offer;
  const formik = useFormik({
    initialValues: {
      county_transfer_tax_or_fee_seller_paid, 
      city_transfer_tax_or_fee_seller_paid, 
      any_private_transfer_fee_seller_paid, 
      hoa_transfer_fee_seller_paid, 
      hoa_fees_for_doc_prep_seller_paid 
    },
    onSubmit: async (values) => {
      update({
        offerId,
        offer: {
          ...offer,
          ...values,
        },
        updatedFields: values,
        setOfflineOffer
      });
      onContinue();
    },
  });

  return (
    <>
      <Header />
      <form
        onSubmit={formik.handleSubmit}
        className="fade-in"
        style={{ display: 'flex', flexDirection: 'column' }}
        action="#"
      >
        <FormControlLabel
          style={{ marginBottom: 28 }}
          control={
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.2)' }}
              {...formik.getFieldProps('county_transfer_tax_or_fee_seller_paid')}
              checked={Boolean(formik.values.county_transfer_tax_or_fee_seller_paid)}
            />
          }
          label={
            <Typography variant="h6" style={{ marginLeft: 4 }}>
              County Transfer Taxes or Fees
            </Typography>
          }
        />
        <FormControlLabel
          style={{ marginBottom: 28 }}
          control={
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.2)' }}
              {...formik.getFieldProps('city_transfer_tax_or_fee_seller_paid')}
              checked={Boolean(formik.values.city_transfer_tax_or_fee_seller_paid)}
            />
          }
          label={
            <Typography variant="h6" style={{ marginLeft: 4 }}>
              City Transfer Taxes or Fees
            </Typography>
          }
        />
        <FormControlLabel
          style={{ marginBottom: 28 }}
          control={
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.2)' }}
              {...formik.getFieldProps('any_private_transfer_fee_seller_paid')}
              checked={Boolean(formik.values.any_private_transfer_fee_seller_paid)}
            />
          }
          label={
            <Typography variant="h6" style={{ marginLeft: 4 }}>
              Any Private Transfer Fees
            </Typography>
          }
        />
        <FormControlLabel
          style={{ marginBottom: 28 }}
          control={
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.2)' }}
              {...formik.getFieldProps('hoa_transfer_fee_seller_paid')}
              checked={Boolean(formik.values.hoa_transfer_fee_seller_paid)}
            />
          }
          label={
            <Typography variant="h6" style={{ marginLeft: 4 }}>
              HOA Transfer Fees
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.2)' }}
              {...formik.getFieldProps('hoa_fees_for_doc_prep_seller_paid')}
              checked={Boolean(formik.values.hoa_fees_for_doc_prep_seller_paid)}
            />
          }
          label={
            <Typography variant="h6" style={{ marginLeft: 4 }}>
              HOA Fees for Document Prep
            </Typography>
          }
        />
        <NextButton loading={updateStatus.isLoading} />
      </form>
    </>
  );
};

const Header = () => (
  <>
    <Typography variant="h5" style={{ marginBottom: 48, fontWeight: 'bold' }}>
      Which taxes or fees do you want the seller to pay&nbsp;for?
    </Typography>
  </>
);

export const ClosingCostsTransferTaxes = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;

  if (response.isFetching || !offer) {
    return (
      <>
        <Header />
        <Skeleton height={35} />
        <Skeleton height={35} />
      </>
    );
  }

  return (
    <ClosingCostsTransferTaxesComponent onContinue={onContinue} offer={offer} />
  );
};
