import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { ClosingCostsEscrowAgent } from './ClosingCostsEscrowAgent';
import { ClosingCostsHomeWarrantyPolicy } from './ClosingCostsHomeWarrantyPolicy';
import { ClosingCostsRareInspections } from './ClosingCostsRareInspections';
import { ClosingCostsTitleInsurancePolicy } from './ClosingCostsTitleInsurancePolicy';
import { ClosingCostsTransferTaxes } from './ClosingCostsTransferTaxes';
export function ClosingCostsRouter() {
  let match = useRouteMatch();
  let history = useHistory();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/home-warranty-policy`}>
          <ClosingCostsHomeWarrantyPolicy
            onContinue={() => {
              history.push(`${match.path}/title-insurance-policy`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/title-insurance-policy`}>
          <ClosingCostsTitleInsurancePolicy
            onContinue={() => {
              history.push(`${match.path}/escrow-agent-fees`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/escrow-agent-fees`}>
          <ClosingCostsEscrowAgent
            onContinue={() => {
              history.push(`${match.path}/transfer-taxes-fees`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/transfer-taxes-fees`}>
          <ClosingCostsTransferTaxes
            onContinue={() => {
              history.push(`${match.path}/rare-inspections`);
            }}
          />
        </Route>
        <Route exact path={`${match.path}/rare-inspections`}>
          <ClosingCostsRareInspections onContinue={() => {
            history.push('/form/review-offer');
          }} />
        </Route>
        <Route>
          <Redirect to={`${match.path}/home-warranty-policy`} />
        </Route>
      </Switch>
    </>
  );
}
