import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { PropertyOverview } from './Property/PropertyOverview';
import { FormHolisticActions } from './FormHolisticActions';
import { PropertyOverviewHeader } from './Property/PropertyOverviewHeader';
import { useAppContext } from '../../AppContext';
import { useReadOffer } from '../../hooks/useReadOffer';
import { ContingencyOverview } from './Contingency/ContingencyOverview';
import { ContactOverview } from './Contact/ContactReview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 760,
    },
    header: {
      marginBottom: theme.spacing(4),
      color: '#434B65',
      fontWeight: 'bold',
    }
  })
);

export function FormOverview() {
  const styles = useStyles();
  const { offerId, offlineOffer } = useAppContext();
  const response = useReadOffer(offerId, offlineOffer);
  const offer = response.data;
  if (response.isFetching) {
    return (
      <>
        <Skeleton height={56} />
        <Skeleton height={56} />
      </>
    );
  }

  if (!offer) {
    return (
      <>
        <Typography variant="h5" className={styles.header}>
          Whoops...
        </Typography>
        <Typography>
          Sorry, we couldn't find your offer! Click <Link to="/home">here</Link> to go back home.
        </Typography>
      </>
    );
  }
  return (
    <div className={styles.root}>
      <PropertyOverviewHeader offer={offer} />
      <PropertyOverview offer={offer} />
      <ContingencyOverview offer={offer} />
      <ContactOverview offer={offer} />
      <FormHolisticActions offer={offer} />
    </div>
  );
}
