import React from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Skeleton from 'react-loading-skeleton'
import * as Sentry from "@sentry/react";

import './GoogleAutocomplete.css';
import { OutlineInputField } from '../../../components/OutlineInputField';
import { PropertyAddress } from '../../../types/models/offer-validation';

const GoogleAutocompleteInternal = function ({
  onSelect,
  onChange,
  defaultValue,
}: {
  onSelect: (address: PropertyAddress) => void;
  onChange: () => void;
  defaultValue?: string | null;
}) {
  const [autocomplete, setAutocomplete] = React.useState<
    google.maps.places.Autocomplete
  >();

  const node = React.useRef<any>(null);
  React.useEffect(() => {
    if (node.current) node.current.focus();
  }, []);

  const fillInAddress = React.useCallback(
    (googleAutocomplete: google.maps.places.Autocomplete) => {
      const components = googleAutocomplete?.getPlace().address_components;
      if (components) {
        const address = convertPlaceToObject(components);
        onSelect(address);
      }
    },
    [onSelect]
  );

  React.useEffect(() => {
    if (!autocomplete) {
      const targetElement = document.getElementById(
        'google-autocomplete'
      ) as HTMLInputElement;

      if (targetElement) {
        const googleAutocomplete = new google.maps.places.Autocomplete(
          targetElement,
          {
            types: ['geocode'],
          }
        );
        googleAutocomplete.setFields(['address_component']);
        googleAutocomplete.addListener('place_changed', () =>
          fillInAddress(googleAutocomplete)
        );
        setAutocomplete(googleAutocomplete);
      }
    }
  }, [autocomplete, onSelect, fillInAddress]);

  return (
    <>
      {/* <input
      className="ant-input"
      // id="google-autocomplete"
      placeholder=""
      onFocus={() => {
        if (autocomplete) geolocate(autocomplete);
      }}
      type="text"
      style={{ width: '100%', height: 48 }}
      
      // ref={node}
    /> */}
      <OutlineInputField
        // className={classes.margin}
        label="Address"
        variant="outlined"
        defaultValue={defaultValue}
        // id="custom-css-outlined-input"
        InputProps={{
          startAdornment: (
            <LocationOnIcon color="primary" style={{ marginRight: 6 }} />
          ),
        }}
        ref={node}
        onChange={() => onChange()}
        id="google-autocomplete"
        placeholder="Street Address, City, State"
      />
    </>
  );
};

function convertPlaceToObject(
  addressComponents: google.maps.GeocoderAddressComponent[]
) {
  const address = {
    street_number: '',
    route: '',
    locality: '',
    sublocality: '',
    sublocality_level_1: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
    neighborhood: '',
    country: '',
    postal_code: '',
    subpremise: '',
  };

  addressComponents.forEach((addressComponent) => {
    const key = addressComponent.types[0];
    if (key in address) {
      // @ts-ignore
      address[key] = addressComponent.long_name;
    }
  });

  const fixedAddresss = {
    property_street_address: `${address.street_number} ${address.route}`,
    property_unit_number: address.subpremise,
    property_city: address.locality || address.sublocality || address.sublocality_level_1,
    property_state: address.administrative_area_level_1,
    property_county: address.administrative_area_level_2,
    property_neighborhood: address.neighborhood,
    property_postal_code: address.postal_code,
  };

  return fixedAddresss;
}

function geolocate(autocomplete: google.maps.places.Autocomplete) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      const geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      const circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy,
      });
      autocomplete.setBounds(circle.getBounds());
    });
  }
}

const render = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return <Skeleton height={56}/>
    case Status.FAILURE:
      Sentry.captureMessage('Google maps failed to load.')
      return <h2>Google maps is down. Please try refereshing the page!</h2>
    default:
      // should not hit here b/c we render child of wrapper on success
      return <></>
  }
}

export const GoogleAutocomplete = function (props: {
  onSelect: (address: PropertyAddress) => void;
  onChange: () => void;
  defaultValue?: string | null;
}) {
  return <Wrapper apiKey={'AIzaSyCAD4Ih_gYk6Ks46KeI2vc7AJoPz5xkzoE'} libraries={['places']} render={render}>
    <GoogleAutocompleteInternal {...props}/>
  </Wrapper>
}