import React, { createContext, useContext } from 'react';
import * as Sentry from "@sentry/react";
import { User } from 'react-netlify-identity';
import { Offer } from './types/models/offer';
import { offerSchema } from './types/models/offer-validation';
import { createOffer } from './lib/client';

// @ts-ignore
const AppContext = createContext<ReturnType<typeof useValues>>({});

// export for jest
export function safeJsonParseOffer(payload: string | null): Offer | null {
  if (payload) {
    try {
      var obj = JSON.parse(payload);
      return offerSchema.validateSync(obj) as Offer;
    } catch (e) {
      console.log(e);;
    }
  }
  return null
}

function useValues() {
  const [isAuthModalOpen, setIsAuthModalOpen] = React.useState(false);
  const [offerId, _setOfferId] = React.useState<string>(
    localStorage.getItem('offerId') ?? ''
  );
  const [offlineOffer, _setOfflineOffer] = React.useState<Offer | null>(
    safeJsonParseOffer(localStorage.getItem('offer'))
  );
  const [FAQ, setFAQ] = React.useState<
    Array<{ header: string; description: JSX.Element }>
  >([]);

  const setOfferId = (newOfferId: string) => {
    localStorage.setItem('offerId', newOfferId);
    _setOfferId(newOfferId);
  };

  const setOfflineOffer = (offer: Offer | null) => {
    if (offer) {
      localStorage.setItem('offer', JSON.stringify(offer));
    } else {
      localStorage.removeItem('offer')
    }
    _setOfflineOffer(offer);
  }

  const attachOfflineOfferToUserIfValid = async (
    user: User | undefined, 
    offlineOffer: Offer | null, 
    setOfflineOffer: (offer: Offer | null) => void, 
    setOfferId: (newOfferId: string) => void): Promise<void> => {
    if (!user || !offlineOffer) {
      return
    }
  
    offlineOffer.user_id = user.id;
    try {
      const response = await createOffer(user, offlineOffer)
      console.log(response)
      setOfferId(response.data.ref['@ref'].id);
      setOfflineOffer(null);
    } catch (e) {
      // log error and swallow
      Sentry.captureException(e);
    }
  }

  return {
    isAuthModalOpen,
    setIsAuthModalOpen,
    offerId,
    setOfferId,
    offlineOffer,
    setOfflineOffer,
    FAQ,
    setFAQ,
    attachOfflineOfferToUser: attachOfflineOfferToUserIfValid,
  };
}

export function AppContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const values = useValues();
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  return useContext(AppContext);
}
