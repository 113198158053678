import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FormMenu } from './FormMenu';
import { PropertyRouter } from './Property/PropertyRouter';
import { ContingencyRouter } from './Contingency/ContingencyRouter';
import { useWindowSize } from '../../hooks/useWindowSize';

import './css/BigQuestionButton.css';
import './css/Button.css';
import { HomeSaleContingencyRouter } from './HomeSaleContingency/HomeSaleContingencyRouter';
import { ClosingCostsRouter } from './ClosingCosts/ClosingCostsRouter';
import { FormOverview } from './FormOverview';
import { PersistentFAQ } from './PersistentFAQ';
import { AdditionalTermsRouter } from './AdditionalTerms/AdditionalTermsRouter';
import { ContactRouter } from './Contact/ContactRouter';

const useClasses = makeStyles((theme: Theme) =>
  createStyles({
    FormFaqLayout: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
      padding: '72px 48px 0px 60px',
      boxShadow: 'inset 8px 0px 17px -6px rgb(0 0 0 / .03)',
      overflowY: 'scroll',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        gridTemplateColumns: 'none',
        padding: 36,
        overflowY: 'inherit',
      },
    },
  })
);

export function FormRouter() {
  let match = useRouteMatch();
  const classes = useClasses();
  const { width } = useWindowSize();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: width < 576 ? '1fr' : '384px 1fr',
        height: '100%',
      }}
    >
      {width >= 576 && (
        <div
          style={{
            backgroundColor: 'white',
            padding: '64px 48px 0px 48px',
            position: 'relative',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormMenu />
          </div>
        </div>
      )}
      <div className={classes.FormFaqLayout}>
        <div>
          <Switch>
            <Route path={`${match.path}/property`}>
              <PropertyRouter />
            </Route>
            <Route path={`${match.path}/contingency`}>
              <ContingencyRouter />
            </Route>
            <Route path={`${match.path}/home-sale-contingency`}>
              <HomeSaleContingencyRouter />
            </Route>
            <Route path={`${match.path}/closing-costs`}>
              <ClosingCostsRouter />
            </Route>
            <Route path={`${match.path}/additional-terms`}>
              <AdditionalTermsRouter />
            </Route>
            <Route path={`${match.path}/contact`}>
              <ContactRouter />
            </Route>
            <Route exact path={`${match.path}/review-offer`}>
              <FormOverview />
            </Route>
            <Route>
              <Redirect to="/form/review-offer" />
            </Route>
          </Switch>
        </div>

        <PersistentFAQ />
      </div>
    </div>
  );
}
